import React, { useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TableContainer, Paper } from "@mui/material";
import InlineDetection from "./InlineDetection";

import { Detection, MeasurementScale, DisplayColumns, EdgeProcessConfig } from "../types";
import { formatDate, getColumnNames, scaleSpeed, scaleDistance } from "../utils";
import { isSuperuser } from "../utils/auth";
import ProductionConfigDisplay from "./ProductionConfigDisplay";
import { COLOR_CONFIDENCE_PERCENTAGE_INVALID, COLOR_CONFIDENCE_PERCENTAGE_UNDER_90, COLOR_CONFIDENCE_PERCENTAGE_UNDER_95, COLOR_CONFIDENCE_PERCENTAGE_OTHERS } from "../config";

interface DetectionListProps {
  detections: Detection[];
  has_raised_axle_model?: boolean;
  measurement_scale?: MeasurementScale;
  display_columns?: DisplayColumns;
  enableSort?: boolean;
  sort_column?: string;
  sort_direction?: "asc" | "desc";
  onVerified?: (d: Detection) => void;
  onExpanded?: (d: Detection, expanded: boolean) => void;
  processConfigs: { [id: number]: EdgeProcessConfig };
}

interface DetectionRowProps {
  detection: Detection;
  has_raised_axle_model?: boolean;
  measurement_scale?: MeasurementScale;
  columnNames: string[];
  onVerified?: (d: Detection) => void;
  onExpanded?: (d: Detection, expanded: boolean) => void;
  processConfigs: { [id: number]: EdgeProcessConfig };
  isAssociated: boolean;
}

interface ColumnMap {
  [columnName: string]: React.ReactNode;
}

function getPercentageColor(percentage?: number): string {
  if (typeof percentage !== "number") {
    return COLOR_CONFIDENCE_PERCENTAGE_INVALID;
  }
  if (percentage <= 0.9) {
    return COLOR_CONFIDENCE_PERCENTAGE_UNDER_90;
  }
  if (percentage <= 0.95) {
    return COLOR_CONFIDENCE_PERCENTAGE_UNDER_95;
  }
  return COLOR_CONFIDENCE_PERCENTAGE_OTHERS;
}

const DetectionRow: React.FC<DetectionRowProps> = ({
  detection,
  onVerified,
  onExpanded,
  has_raised_axle_model = false,
  measurement_scale = "metric",
  columnNames = [],
  processConfigs,
  isAssociated = false,
}) => {
  const processConfig = processConfigs[detection.process_id];
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const ref = useRef<HTMLTableRowElement>(null);
  const location = useLocation();
  const [showConfigs, setShowConfigs] = React.useState<boolean>(false);

  const sx = expanded ? { borderBottom: 0 } : null;

  const handleExpanded = () => {
    if (onExpanded) {
      onExpanded(detection, !expanded);
    }
    setExpanded(!expanded);
  };

  const toggleShowConfigs = () => {
    setShowConfigs(!showConfigs);
  };

  useEffect(() => {
    const hash = location.hash.substr(1);
    if (ref.current && hash) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, id] = hash.split("-");
      if (+id === detection.id) {
        ref.current.scrollIntoView();
      }
    }
  }, [location, detection.id]);

  const vehicle_class =
    detection.verified_vehicle_class !== null && detection.vehicle_class !== detection.verified_vehicle_class
      ? `${detection.verified_vehicle_class} (${detection.vehicle_class})`
      : detection.vehicle_class;
  const vehicle_subclass =
      detection.verified_vehicle_subclass !== null && detection.vehicle_subclass !== detection.verified_vehicle_subclass
        ? `${detection.verified_vehicle_subclass} (${detection.vehicle_subclass})`
        : detection.vehicle_subclass;
  const num_axles =
    detection.verified_num_tires !== null && detection.num_tires !== detection.verified_num_tires
      ? `${detection.verified_num_tires} (${detection.num_tires})`
      : detection.num_tires < 2
      ? `2 (${detection.num_tires})`
      : detection.num_tires;
  const num_raised_axles =
    detection.verified_num_raised_tires !== null && detection.num_raised_tires !== detection.verified_num_raised_tires
      ? `${detection.verified_num_raised_tires} (${detection.num_raised_tires})`
      : detection.num_raised_tires;
  const num_raised_axles_ml =
    detection.verified_num_raised_tires !== null && detection.num_raised_tires_ml !== detection.verified_num_raised_tires
      ? `${detection.verified_num_raised_tires} (${detection.num_raised_tires_ml ?? 0})`
      : detection.num_raised_tires_ml ?? 0;

  const columnMap: ColumnMap = {
    thumbnail: (
      <TableCell key="thumbnail" sx={{ padding: 0, margin: 0, maxWidth: "200px", overflow: "clip", borderBottom: expanded ? 0 : null }}>
        <img alt="" style={{ maxHeight: "5rem" }} src={`${BASE_IMAGE_URL}${detection.process_id}/thumbnail-${detection.vehicle_number}.png`} />
      </TableCell>
    ),
    vehicle_number: (
      <TableCell key="vehicle_number" sx={{ fontWeight: "bold", borderBottom: expanded ? 0 : null }}>
        {detection.associated_detections && detection.associated_detections.length > 0 && expanded && <div>Primary</div>}
        {isAssociated && <div>Secondary</div>}
        <a target="_blank" rel="noreferrer" href={`/detections/${detection.id}`} onClick={(e) => e.stopPropagation()}>
          {detection.vehicle_number}
        </a>
      </TableCell>
    ),
    verified_associated_vehicle_numbers: (
      <TableCell key="verified_associated_vehicle_numbers" sx={sx}>
        {detection.verified_associated_vehicle_numbers &&
          detection.verified_associated_vehicle_numbers.map((number, index) => {
            const detectionId = detection.verified_associated_detection_ids ?? [index];
            return (
              <div key={index}>
                <a style={{ color: "red", display: "block" }} target="_blank" rel="noreferrer" href={`/detections/${detectionId[index]}`} onClick={(e) => e.stopPropagation()}>
                  {number}
                </a>
              </div>
            );
          })}
      </TableCell>
    ),
    verified_association_type: (
      <TableCell
        key="verified_association_type"
        sx={sx}
        style={{ color: detection.verified_association_type === "Duplicate" || detection.verified_association_type === "Split" ? "red" : "inherit" }}
      >
        {" "}
        {detection.verified_association_type ?? ""}
      </TableCell>
    ),
    vehicle_class: <TableCell key="vehicle_class" sx={sx}>{vehicle_class}</TableCell>,
    vehicle_subclass: <TableCell key="vehicle_subclass" sx={sx}>{vehicle_subclass}</TableCell>,
    start_time: <TableCell key="start_time" sx={sx}>{formatDate(detection.start_time, 2)}</TableCell>,
    end_time: <TableCell key="end_time" sx={sx}>{formatDate(detection.end_time, 2)}</TableCell>,
    duration: <TableCell key="duration" sx={sx}>{Math.ceil((detection.end_time.valueOf() - detection.start_time.valueOf()) / 1000)}</TableCell>,
    speed: <TableCell key="speed" sx={sx}>{scaleSpeed(measurement_scale, detection.speed)}</TableCell>,
    length: <TableCell key="length" sx={sx}>{scaleDistance(measurement_scale, detection.length)}</TableCell>,
    height: <TableCell key="height" sx={sx}>{scaleDistance(measurement_scale, detection.height)}</TableCell>,
    straddle: <TableCell key="straddle" sx={sx}>{detection.straddle ?? "None"}</TableCell>,
    axle_count: <TableCell key="num_axles" sx={sx}>{num_axles}</TableCell>,
    raised_count: <TableCell key="raised_count" sx={sx}>{num_raised_axles}</TableCell>,
    raised_axle_indices: <TableCell key="raised_axle_indices" sx={sx}>{detection.raised_tires.join(", ")}</TableCell>,
    raised_count_ml: <TableCell key="raised_count_ml" sx={sx}>{num_raised_axles_ml}</TableCell>,
    raised_axle_indices_ml: <TableCell key="raised_axle_indices_ml" sx={sx}>{detection.raised_tires_ml?.join(", ")}</TableCell>,
    vehicle_stats: (
      <TableCell key="vehicle_stats" sx={sx}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <div style={{ color: getPercentageColor(detection.vehicle_minimum_mode) }}>{detection.vehicle_minimum_mode ? (detection.vehicle_minimum_mode * 100).toFixed(0) : ""}</div>
          <div style={{ color: getPercentageColor(detection.minimum_vehicle_score) }}>
            {detection.minimum_vehicle_score ? (detection.minimum_vehicle_score * 100).toFixed(2) : ""}
          </div>
          <div style={{ color: getPercentageColor(detection.vehicle_lowest_maximum) }}>
            {detection.vehicle_lowest_maximum ? (detection.vehicle_lowest_maximum * 100).toFixed(2) : ""}
          </div>
        </div>
      </TableCell>
    ),
    axle_stats: (
      <TableCell key="axle_stats" sx={sx}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <div style={{ color: getPercentageColor(detection.axle_minimum_mode) }}>{detection.axle_minimum_mode ? (detection.axle_minimum_mode * 100).toFixed(0) : ""}</div>
          <div style={{ color: getPercentageColor(detection.minimum_tire_score) }}>{detection.minimum_tire_score ? (detection.minimum_tire_score * 100).toFixed(2) : ""}</div>
          <div style={{ color: getPercentageColor(detection.axle_lowest_maximum) }}>{detection.axle_lowest_maximum ? (detection.axle_lowest_maximum * 100).toFixed(2) : ""}</div>
        </div>
      </TableCell>
    ),
    expand: (
      <TableCell key="expand" sx={sx}>
        <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </TableCell>
    ),
  };
  const columns = columnNames.map((cn) => columnMap[cn]);

  const style = {
    backgroundColor: isAssociated ? "#e0c6f0" : detection.associated_detections && detection.associated_detections.length > 0 && expanded ? "#f0e4fc" : "inherit",
  };
  return (
    <>
      <TableRow ref={ref} onClick={handleExpanded} hover style={style}>
        {columns}
      </TableRow>
      {expanded && (
        <>
          {processConfig && (
            <>
              <TableRow style={style}>
                <TableCell colSpan={columns.length} sx={{ ...sx, padding: 1 }}>
                  <Button
                    size="small"
                    onClick={toggleShowConfigs}
                    endIcon={showConfigs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      color: "black",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {showConfigs ? 'Hide Process Configs' : 'Show Process Configs'}
                  </Button>
                  {showConfigs && <ProductionConfigDisplay viewId={detection.view_id} currentProcessConfig={processConfig} canModify={false} />}
                </TableCell>
              </TableRow>
            </>
          )}
          <TableRow style={style}>
            <TableCell colSpan={columns.length}>
              <InlineDetection detection={detection} onVerified={onVerified} processConfig={processConfig} />
            </TableCell>
          </TableRow>
          {(detection.associated_detections ?? []).map((d) => (
            <DetectionRow
              key={d.id}
              detection={d}
              onVerified={onVerified}
              onExpanded={onExpanded}
              has_raised_axle_model={has_raised_axle_model}
              measurement_scale={measurement_scale}
              columnNames={columnNames}
              processConfigs={processConfigs}
              isAssociated={true}
            />
          ))}
        </>
      )}
    </>
  );
};

const BASE_IMAGE_URL = "https://s3.ca-central-1.amazonaws.com/axle-images.aerialmachinelearningsystems.com/";

const DetectionList: React.FC<DetectionListProps> = ({
  detections,
  onVerified,
  enableSort = true,
  sort_column,
  sort_direction = "desc",
  has_raised_axle_model = false,
  measurement_scale = "metric",
  display_columns,
  onExpanded,
  processConfigs,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClickSort = (column: string) => {
    searchParams.set("sort_column", column);
    if (sort_direction === "desc") {
      searchParams.set("sort_direction", "asc");
    } else {
      searchParams.set("sort_direction", "desc");
    }
    setSearchParams(searchParams);
    window.scrollTo({ top: 0 });
  };
  const columnNames = getColumnNames(has_raised_axle_model, display_columns);
  const columnMap: ColumnMap = {
    thumbnail: <TableCell key="thumbnail">&nbsp;</TableCell>,
    vehicle_number: (
      <TableCell key="vehicle_number">
        Vehicle ID {enableSort && <TableSortLabel onClick={() => onClickSort("vehicle_number")} active={"vehicle_number" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    verified_associated_vehicle_numbers: <TableCell key="verified_associated_vehicle_numbers">Associated Vehicle IDs </TableCell>,
    verified_association_type: (
      <TableCell key="verified_association_type">
        Association Type{" "}
        {enableSort && <TableSortLabel onClick={() => onClickSort("verified_association_type")} active={"verified_association_type" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    vehicle_class: (
      <TableCell key="vehicle_class">
        Class {enableSort && <TableSortLabel onClick={() => onClickSort("vehicle_class")} active={"vehicle_class" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    vehicle_subclass: (
      <TableCell key="vehicle_subclass">
        Toll Class # {enableSort && <TableSortLabel onClick={() => onClickSort("vehicle_subclass")} active={"vehicle_subclass" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    start_time: (
      <TableCell key="start_time">
        Start {enableSort && <TableSortLabel onClick={() => onClickSort("start_time")} active={"start_time" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    end_time: (
      <TableCell key="end_time">
        End {enableSort && <TableSortLabel onClick={() => onClickSort("end_time")} active={"end_time" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    duration: (
      <TableCell key="duration">
        Duration (s) {enableSort && <TableSortLabel onClick={() => onClickSort("duration")} active={"duration" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    speed: (
      <TableCell key="speed">
        Speed ({"metric" === measurement_scale ? "km/h" : "mph"})
        {enableSort && <TableSortLabel onClick={() => onClickSort("speed")} active={"speed" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    length: (
      <TableCell key="length">
        Length ({"metric" === measurement_scale ? "m" : "in"})
        {enableSort && <TableSortLabel onClick={() => onClickSort("length")} active={"length" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    height: (
      <TableCell key="height">
        Height ({"metric" === measurement_scale ? "m" : "in"})
        {enableSort && <TableSortLabel onClick={() => onClickSort("height")} active={"height" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    straddle: (
      <TableCell key="straddle">
        Straddle{enableSort && <TableSortLabel onClick={() => onClickSort("straddle")} active={"straddle" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    axle_count: (
      <TableCell key="num_axles">
        # Total Axles {enableSort && <TableSortLabel onClick={() => onClickSort("num_tires")} active={"num_tires" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    raised_count: (
      <TableCell key="raised_count">
        # Raised {enableSort && <TableSortLabel onClick={() => onClickSort("num_raised_tires")} active={"num_raised_tires" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    raised_axle_indices: <TableCell key="raised_axle_indices">Raised Axle Indices</TableCell>,
    raised_count_ml: (
      <TableCell key="raised_count_ml">
        # Raised{isSuperuser() ? " (ML)" : ""}
        {enableSort && <TableSortLabel onClick={() => onClickSort("num_raised_tires_ml")} active={"num_raised_tires_ml" === sort_column} direction={sort_direction} />}
      </TableCell>
    ),
    raised_axle_indices_ml: <TableCell key="raised_axle_indices_ml">Raised Axle Indices{isSuperuser() ? " (ML)" : ""}</TableCell>,
    vehicle_stats: (
      <TableCell key="vehicle_stats">
        <div
          style={{ 
            textAlign: "center", 
            marginBottom: "8px", 
            textTransform: "uppercase", 
            borderBottom: "2px solid #ddd",
            paddingBottom: "4px"
          }}
        >
          Vehicle
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <div style={{ padding: "0px 10px" }}>
            Min Mode{" "}
            {enableSort && <TableSortLabel onClick={() => onClickSort("vehicle_minimum_mode")} active={"vehicle_minimum_mode" === sort_column} direction={sort_direction} />}
          </div>
          <div style={{ padding: "0px 10px" }}>
            Min Score{" "}
            {enableSort && <TableSortLabel onClick={() => onClickSort("minimum_vehicle_score")} active={"minimum_vehicle_score" === sort_column} direction={sort_direction} />}
          </div>
          <div style={{ padding: "0px 10px" }}>
            Lowest Max{" "}
            {enableSort && <TableSortLabel onClick={() => onClickSort("vehicle_lowest_maximum")} active={"vehicle_lowest_maximum" === sort_column} direction={sort_direction} />}
          </div>
        </div>
      </TableCell>
    ),
    axle_stats: (
      <TableCell key="axle_stats">
                <div
          style={{ 
            textAlign: "center", 
            marginBottom: "8px", 
            textTransform: "uppercase", 
            borderBottom: "2px solid #ddd",
            paddingBottom: "4px"
          }}
        >
          Axles
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <div style={{ padding: "0px 10px" }}>
            Min Mode {enableSort && <TableSortLabel onClick={() => onClickSort("axle_minimum_mode")} active={"axle_minimum_mode" === sort_column} direction={sort_direction} />}
          </div>
          <div style={{ padding: "0px 10px" }}>
            Min Score {enableSort && <TableSortLabel onClick={() => onClickSort("minimum_tire_score")} active={"minimum_tire_score" === sort_column} direction={sort_direction} />}
          </div>
          <div style={{ padding: "0px 10px" }}>
            Lowest Max{" "}
            {enableSort && <TableSortLabel onClick={() => onClickSort("axle_lowest_maximum")} active={"axle_lowest_maximum" === sort_column} direction={sort_direction} />}
          </div>
        </div>
      </TableCell>
    ),
    expand: <TableCell key="expand">&nbsp;</TableCell>,
  };
  const columns = columnNames.map((cn) => columnMap[cn]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "100vh", overflow: "auto" }}>
      <Table aria-label="Detections" stickyHeader>
        <TableHead>
          <TableRow>{columns}</TableRow>
        </TableHead>
        <TableBody>
          {detections.map((detection) => (
            <DetectionRow
              key={detection.id}
              detection={detection}
              onVerified={onVerified}
              onExpanded={onExpanded}
              has_raised_axle_model={has_raised_axle_model}
              measurement_scale={measurement_scale}
              columnNames={columnNames}
              processConfigs={processConfigs}
              isAssociated={false}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetectionList;
