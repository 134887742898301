import React, { useEffect, useRef } from 'react';
import { useLocation, useHref } from 'react-router-dom';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VerifiedIcon from '@mui/icons-material/Verified';

import InlineDetection from './InlineDetection';


import { Comparison, DetectionObservation, Observation, Detection, View, MeasurementScale, EdgeProcessConfig } from '../types';
import { formatDate, isDetectionVerified, isMatched, isUnverified, isMissed, getColumnNames, scaleSpeed, scaleDistance } from '../utils';
import { isSuperuser } from '../utils/auth';
import { COLOR_UNVERIFIED_DETECTION, COLOR_VERIFIED_DETECTION, COLOR_NO_DETECTION, COLOR_NO_OBSERVATION, COLOR_MISSED_DETECTION } from '../config';

const BASE_IMAGE_URL = 'https://s3.ca-central-1.amazonaws.com/axle-images.aerialmachinelearningsystems.com/';

interface ColumnMap {
  [columnName: string]: React.ReactNode;
}

interface ComparisonRowProps {
  comparison: DetectionObservation;
  selectedObservation?: Observation;
  selectedDetection?: Detection;
  onLinkObservation: (o: Observation) => Promise<void>;
  onLinkDetection: (d: Detection) => Promise<void>;
  onUnlink: (o: Observation) => Promise<void>;
  onVerified?: (d: Detection) => void;
  measurement_scale: MeasurementScale;
  columnNames: string[];
  processConfigs: { [id: number]: EdgeProcessConfig };
};

const ComparisonRow: React.FC<ComparisonRowProps> = ({
  comparison,
  selectedObservation,
  selectedDetection,
  onLinkObservation,
  onLinkDetection,
  onUnlink,
  onVerified,
  measurement_scale,
  columnNames,
  processConfigs
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const ref = useRef<HTMLTableRowElement>(null);
  const location = useLocation();
  const href = useHref(location);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [base_href, _] = href.split('#');
  
  const detection = comparison.detection;
  const processConfig = detection ? processConfigs[detection.process_id] : undefined;
  const observation = comparison.observation;
  const isObservation = !!observation;
  const isDetection = !!detection;
  const isBoth = isObservation && isDetection;
  const delta = Math.round(isBoth ? (observation.date_time.valueOf() - detection.end_time.valueOf()) / 1000 : 0);
  const isUserGenerated = !observation?.is_primary_time
  const isVerified = isDetection && isDetectionVerified(detection);
  const isComparisonVerified = (m: DetectionObservation) => !!m.detection && !!m.observation && m.detection.verified_num_tires !== null;

  const backgroundColor = isMatched(comparison) ? null : (
    !isObservation ? COLOR_NO_OBSERVATION : (
    !isDetection ? COLOR_NO_DETECTION : (
    isUnverified(comparison) ? COLOR_UNVERIFIED_DETECTION : (
    isMissed(comparison) ? COLOR_MISSED_DETECTION : (
    isComparisonVerified(comparison) ? COLOR_VERIFIED_DETECTION : null
    )
  ))));

  const sx_d = {
    borderBottom: isObservation ? 'none': null,
    backgroundColor
  };
  const sx_o = {
    backgroundColor
  }

  useEffect(() => {
    const hash = location.hash.substr(1);
    if (ref.current && hash) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, id] = hash.split('-');
      if (isDetection && +id === detection.id) {
        ref.current.scrollIntoView();
      }
    }
  }, [location, detection, isDetection]);

  const vehicle_class = isDetection && (
    detection.verified_vehicle_class !== null && detection.vehicle_class !== detection.verified_vehicle_class ?
    `${detection.verified_vehicle_class} (${detection.vehicle_class})` :
    detection.vehicle_class
  );
  const vehicle_subclass = isDetection && (
    detection.verified_vehicle_subclass !== null && detection.vehicle_subclass !== detection.verified_vehicle_subclass ?
    `${detection.verified_vehicle_subclass} (${detection.vehicle_subclass})` :
    detection.vehicle_subclass
  );
  const num_axles = isDetection && (
    detection.verified_num_tires !== null && detection.num_tires !== detection.verified_num_tires ?
   `${detection.verified_num_tires} (${detection.num_tires})` :
    (detection.num_tires < 2 ? `2 (${detection.num_tires})` : detection.num_tires)
  );
  const num_raised_axles = isDetection && (
    detection.verified_num_raised_tires !== null && detection.num_raised_tires !== detection.verified_num_raised_tires ?
   `${detection.verified_num_raised_tires} (${detection.num_raised_tires})` :
    detection.num_raised_tires
  );
  const num_raised_axles_ml = isDetection && (
    detection.verified_num_raised_tires !== null && detection.num_raised_tires_ml !== detection.verified_num_raised_tires ?
   `${detection.verified_num_raised_tires} (${detection.num_raised_tires_ml})` :
    detection.num_raised_tires_ml
  );
  const num_lowered_axles = isDetection && (
    (detection.verified_num_tires !== null || detection.verified_num_raised_tires !== null) &&
    (detection.verified_num_tires !== detection.num_tires || detection.verified_num_raised_tires !== detection.num_raised_tires) &&
    (Math.max((detection.verified_num_tires ?? detection.num_tires) - (detection.verified_num_raised_tires ?? detection.num_raised_tires), 0) !== Math.max(detection.num_tires - detection.num_raised_tires, 0)) ?
    (`${Math.max((detection.verified_num_tires ?? detection.num_tires) - (detection.verified_num_raised_tires ?? detection.num_raised_tires), 0)} (${Math.max(detection.num_tires - detection.num_raised_tires, 0)})`) : 
    (detection.num_tires < 2 && (Math.max(2 - detection.num_raised_tires, 0) !== detection.num_tires - detection.num_raised_tires) ? `${Math.max(2 - detection.num_raised_tires, 0)} (${detection.num_tires - detection.num_raised_tires})` : (detection.num_tires - detection.num_raised_tires))
  );
  const num_lowered_axles_ml = isDetection && (
    (detection.verified_num_tires !== null || detection.verified_num_raised_tires !== null) &&
    (detection.verified_num_tires !== detection.num_tires || detection.verified_num_raised_tires !== detection.num_raised_tires_ml) &&
    (Math.max((detection.verified_num_tires ?? detection.num_tires) - (detection.verified_num_raised_tires ?? detection.num_raised_tires_ml ?? 0), 0) !== Math.max(detection.num_tires - (detection.num_raised_tires_ml ?? 0), 0)) ?
    (`${Math.max((detection.verified_num_tires ?? detection.num_tires) - (detection.verified_num_raised_tires ?? detection.num_raised_tires_ml ?? 0), 0)} (${Math.max(detection.num_tires - (detection.num_raised_tires_ml ?? 0), 0)})`) : 
    (detection.num_tires < 2 && (Math.max(2 - (detection.num_raised_tires_ml ?? 0), 0) !== detection.num_tires - (detection.num_raised_tires_ml ?? 0)) ? `${Math.max(2 - (detection.num_raised_tires_ml ?? 0), 0)} (${detection.num_tires - (detection.num_raised_tires_ml ?? 0)})` : (detection.num_tires - (detection.num_raised_tires_ml ?? 0)))
  );

  const handleLinkDetection: (e: React.MouseEvent<HTMLButtonElement>) => void = e => {
    e.stopPropagation();
    if (!detection) {
      return;
    }
    onLinkDetection(detection);
  };

  const handleLinkObservation: (e: React.MouseEvent<HTMLButtonElement>) => void = e => {
    e.stopPropagation();
    if (!observation) {
      return;
    }
    onLinkObservation(observation);
  };

  const handleUnlink: (e: React.MouseEvent<HTMLButtonElement>) => void = e => {
    e.stopPropagation();
    if (!observation) {
      return;
    }
    onUnlink(observation);
  };

  const isLinked = !!observation && !!observation.detection_id;
  const isDetectionSelected = selectedDetection === detection;
  const isObservationSelected = selectedObservation === observation;
  const isOtherDetectionSelected = !!selectedDetection && !isDetectionSelected;
  const isOtherObservationSelected = !!selectedObservation && !isObservationSelected;
  const canLinkDetection = !isLinked && !isDetectionSelected;
  const canUnselectDetection = isDetectionSelected;
  const canLinkObservation = !isLinked && !isObservationSelected;
  const canUnselectObservation = isObservationSelected;

  const sx_b = { visibility: 'hidden' };

  const detectionButtonCell = <TableCell key="action" sx={sx_d} rowSpan={isLinked ? 2 : 1}>
    {isLinked ? <Button onClick={handleUnlink}>Unlink</Button> : (isBoth || isOtherDetectionSelected? <span>&nbsp;</span> : (canLinkDetection ? <Button onClick={handleLinkDetection} sx={sx_b}>Link</Button> : (canUnselectDetection ? <Button onClick={handleLinkDetection}>Unselect</Button> : <span>&nbsp;</span>)))}
    <IconButton>{expanded ? <ExpandLess/> : <ExpandMore />}</IconButton>
  </TableCell>;

  const observationButtonCell = !isLinked && <TableCell key="action" sx={sx_o}>{isLinked ? <Button sx={sx_b}>Unlink</Button> : (isBoth || isOtherObservationSelected ? <span>&nbsp;</span> : (canLinkObservation ? <Button onClick={handleLinkObservation} sx={sx_b}>Link</Button> : (canUnselectObservation ? <Button onClick={handleLinkObservation}>Unselect</Button> : <span>&nbsp;</span>)))}</TableCell>;

  const dColumnMap: ColumnMap = {};
  if (isDetection) {
    dColumnMap['thumbnail'] = <TableCell key="thumbnail" sx={{ maxWidth: '200px', overflow: 'clip', padding: 0, margin: 0, ...sx_d }}>
      <img
        alt=""
        style={{ maxHeight: '5rem' }}
        src={`${BASE_IMAGE_URL}${detection.process_id}/thumbnail-${detection.vehicle_number}.png`}
      />
    </TableCell>;
    dColumnMap['vehicle_number'] = <TableCell key="vehicle_number" sx={sx_d}><a target="_blank" rel="noreferrer" href={`/detections/${detection.id}`}>{detection.vehicle_number}</a></TableCell>;
    dColumnMap['vehicle_class'] = <TableCell key="vehicle_class" sx={sx_d}>{vehicle_class}</TableCell>;
    dColumnMap['verified_associated_vehicle_numbers']= <TableCell key="verified_associated_vehicle_numbers" sx={sx_d}>
    {detection.verified_associated_vehicle_numbers &&
      detection.verified_associated_vehicle_numbers.map((number, index) => {
        const detectionId = detection.verified_associated_detection_ids ?? [index];
        console.log(detectionId);
        return (
          <div key={index}>
            <a style={{ color: "red", display: "block" }} target="_blank" rel="noreferrer" href={`/detections/${detectionId[index]}`} color='red' onClick={(e) => e.stopPropagation()}>
              {number}
            </a>
          </div>
        );
      })}
      </TableCell>;
    dColumnMap['verified_association_type'] = <TableCell key="verified_association_type" style={{ color: detection.verified_association_type === 'Duplicate' || detection.verified_association_type === 'Split' ? 'red' : 'inherit' }} sx={sx_d}> {detection.verified_association_type ?? ""}</TableCell>;
    dColumnMap['vehicle_subclass'] = <TableCell key="vehicle_subclass" sx={sx_d}>{vehicle_subclass}</TableCell>;
    dColumnMap['start_time'] = <TableCell key="start_time" sx={sx_d}>{formatDate(detection.start_time, 2)}</TableCell>;
    dColumnMap['end_time'] = <TableCell key="end_time" sx={sx_d}>{formatDate(detection.end_time, 2)}</TableCell>;
    dColumnMap['audit_delta'] = <TableCell key="audit_delta" sx={sx_d}>{isObservation ? delta : <span>&nbsp;</span>}</TableCell>;
    dColumnMap['speed'] = <TableCell key="speed" sx={sx_d}>{scaleSpeed(measurement_scale, detection.speed)}</TableCell>;
    dColumnMap['length'] = <TableCell key="length" sx={sx_d}>{scaleDistance(measurement_scale, detection.length)}</TableCell>;
    dColumnMap['height'] = <TableCell key="height" sx={sx_d}>{scaleDistance(measurement_scale, detection.height)}</TableCell>;
    dColumnMap['direction'] = <TableCell key="direction" sx={sx_d}>&nbsp;</TableCell>;
    dColumnMap['straddle'] = <TableCell key="straddle" sx={sx_d}>{detection.straddle ?? 'None'}</TableCell>;
    dColumnMap['axle_count'] = <TableCell key="axle_count" sx={sx_d}>{num_axles}</TableCell>;
    dColumnMap['lowered_count'] = <TableCell key="lowered_count" sx={sx_d}>{num_lowered_axles}</TableCell>;
    dColumnMap['raised_count'] =  <TableCell key="raised_count" sx={sx_d}>{num_raised_axles}</TableCell>;
    dColumnMap['raised_axle_indices'] = <TableCell key="raised_axle_indices" sx={sx_d}>{detection.raised_tires.join(', ')}</TableCell>;
    dColumnMap['lowered_count_ml'] = <TableCell key="lowered_count_ml"  sx={sx_d}>{num_lowered_axles_ml}</TableCell>;
    dColumnMap['raised_count_ml'] = <TableCell key="raised_count_ml" sx={sx_d}>{num_raised_axles_ml}</TableCell>;
    dColumnMap['raised_axle_indices_ml'] = <TableCell key="raised_axle_indices_ml" sx={sx_d}>{(detection.raised_tires_ml ?? []).join(', ')}</TableCell>;
    dColumnMap['is_verified'] = <TableCell key="is_verified" sx={sx_d}>{isVerified ? <VerifiedIcon color="success" /> : <span>&nbsp;</span>}</TableCell>;
    dColumnMap['action'] = detectionButtonCell;
  };
  const oColumnMap: ColumnMap = {};
  if (isObservation) {
    oColumnMap['thumbnail'] = <TableCell key="thumbnail" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['vehicle_number'] = <TableCell key="vehicle_number" sx={sx_o}>{observation.rank}</TableCell>;
    oColumnMap['verified_associated_vehicle_numbers'] = <TableCell key="verified_associated_vehicle_numbers" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['verified_association_type'] = <TableCell key="verified_association_type" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['vehicle_class'] = <TableCell key="vehicle_class" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['vehicle_subclass'] = <TableCell key="vehicle_subclass" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['speed'] = <TableCell key="speed" sx={sx_o}>{observation.speed ?? ''}</TableCell>;
    oColumnMap['length'] = <TableCell key="length" sx={sx_o}>{observation.length ?? ''}</TableCell>;
    oColumnMap['height'] = <TableCell key="height" sx={sx_o}>{observation.height ?? ''}</TableCell>;
    oColumnMap['direction'] = <TableCell key="direction" sx={sx_o}>{observation.direction ?? ''}</TableCell>;
    oColumnMap['straddle'] = <TableCell key="straddle" sx={sx_o}>{observation.straddle ?? 'None'}</TableCell>;
    oColumnMap['axle_count'] = <TableCell key="axle_count" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['lowered_count'] = <TableCell key="lowered_count" sx={sx_o}>{observation.num_tires}</TableCell>;
    oColumnMap['lowered_count_ml'] = <TableCell key="lowered_count_ml" sx={sx_o}>{observation.num_tires}</TableCell>;
    oColumnMap['raised_count'] = <TableCell key="raised_count" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['raised_axle_indices'] = <TableCell key="raised_axle_indices" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['raised_count_ml'] = <TableCell key="raised_count_ml" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['raised_axle_indices_ml'] = <TableCell key="raised_axle_indices_ml" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['is_verified'] = <TableCell key="is_verified" sx={sx_o}>&nbsp;</TableCell>;
    oColumnMap['action'] = observationButtonCell;
    if (isUserGenerated) {
      oColumnMap['start_time'] = <TableCell key="start_time" sx={sx_o}>&nbsp;</TableCell>;
      oColumnMap['end_time'] = <TableCell key="end_time" sx={{backgroundColor: backgroundColor, color: '#cc7000'}} >{formatDate(observation.date_time)}</TableCell>;
      oColumnMap['audit_delta'] = <TableCell key="audit_delta" sx={sx_o}>&nbsp;</TableCell>;
    } else {
      oColumnMap['start_time'] = <TableCell key="start_time" sx={sx_o}>{formatDate(observation.date_time)}</TableCell>;
      oColumnMap['end_time'] = <TableCell key="end_time" sx={sx_o}>&nbsp;</TableCell>;
      oColumnMap['audit_delta'] = <TableCell key="audit_delta" sx={sx_o}>&nbsp;</TableCell>;
    }
  }

  const dColumns = columnNames.map(cn => dColumnMap[cn]);
  const oColumns = columnNames.map(cn => oColumnMap[cn]);

  return (
    <TableBody>
      {isDetection && <TableRow sx={{ '&:hover button': { visibility: 'visible' }}} ref={ref} hover onClick={(e) => { e.stopPropagation(); setExpanded(!expanded); }}>
        {dColumns}
      </TableRow>}
      {isDetection && expanded && <TableRow>
        <TableCell colSpan={11}>
          <InlineDetection detection={detection} processConfig={processConfig} onVerified={onVerified} />
        </TableCell>
      </TableRow>}
      {isObservation && <TableRow sx={{ '&:hover button': { visibility: 'visible' }}}>
        {oColumns}
      </TableRow>}
    </TableBody>
  );
};

interface ComparisonListProps {
  comparison: Comparison;
  selectedObservation?: Observation;
  selectedDetection?: Detection;
  onLinkObservation: (o: Observation) => Promise<void>;
  onLinkDetection: (d: Detection) => Promise<void>;
  onUnlink: (o: Observation) => Promise<void>;
  onVerified?: (d: Detection) => void;
  view?: View;
  processConfigs: { [id: number]: EdgeProcessConfig };
}


const ComparisonList: React.FC<ComparisonListProps> = ({
  comparison,
  selectedObservation,
  selectedDetection,
  onLinkObservation,
  onLinkDetection,
  onUnlink,
  onVerified,
  view,
  processConfigs
}) => {
  
  const measurement_scale = view?.measurement_scale ?? 'metric';
  const columnNames = getColumnNames(!!view?.has_raised_axle_model, view?.display_columns);
  const columnMap: ColumnMap = {
    'thumbnail': <TableCell key="thumbnail">&nbsp;</TableCell>,
    'vehicle_number': <TableCell key="vehicle_number">Vehicle ID</TableCell>,
    'verified_associated_vehicle_numbers': <TableCell key="verified_associated_vehicle_numbers">Associated Vehicle IDs </TableCell>,
    'verified_association_type': <TableCell key="verified_association_type">Association Type </TableCell>,
    'vehicle_class': <TableCell key="vehicle_class">Class</TableCell>,
    'vehicle_subclass': <TableCell key="vehicle_subclass">Toll Class #</TableCell>,
    'start_time': <TableCell key="start_time">Start</TableCell>,
    'end_time': <TableCell key="end_time">End</TableCell>,
    'audit_delta': <TableCell key="audit_delta">Delta</TableCell>,
    'speed': <TableCell key="speed">Speed ({'metric' === measurement_scale ? 'km/h' : 'mph'})</TableCell>,
    'length': <TableCell key="length">Length ({'metric' === measurement_scale ? 'm' : 'in'})</TableCell>,
    'height': <TableCell key="height">Height ({'metric' === measurement_scale ? 'm' : 'in'})</TableCell>,
    'direction': <TableCell key="direction">Direction</TableCell>,
    'straddle': <TableCell key="straddle">Straddle</TableCell>,
    'axle_count': <TableCell key="axle_count"># Total Axles</TableCell>,
    'lowered_count': <TableCell key="lowered_count"># Lowered</TableCell>,
    'raised_count': <TableCell key="raised_count"># Raised</TableCell>,
    'raised_axle_indices': <TableCell key="raised_axle_indices">Raised Axle Indices</TableCell>,
    'lowered_count_ml': <TableCell key="lowered_count_ml"># Lowered{isSuperuser() ? ' (ML)' : ''}</TableCell>,
    'raised_count_ml': <TableCell key="raised_count_ml"># Raised{isSuperuser() ? ' (ML)' : ''}</TableCell>,
    'raised_axle_indices_ml': <TableCell key="raised_axle_indices_ml">Raised Axle Indices{isSuperuser() ? ' (ML)' : ''}</TableCell>,
    'is_verified': <TableCell key="is_verified">Verified</TableCell>,
    'action': <TableCell key="action">&nbsp;</TableCell>
  };
  const columns = columnNames.map(cn => columnMap[cn]);
  return (
    <Table aria-label="Comparisons">
      <TableHead>
        <TableRow>
          {columns}
        </TableRow>
      </TableHead>
      {comparison.matches.map(
        (m, i) => <ComparisonRow key={i} comparison={m} selectedObservation={selectedObservation} selectedDetection={selectedDetection} onLinkDetection={onLinkDetection} onLinkObservation={onLinkObservation} onUnlink={onUnlink} onVerified={onVerified} measurement_scale={measurement_scale} columnNames={columnNames} processConfigs={processConfigs} />)
      }
    </Table>
  );
};

export default ComparisonList;
