import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Button from '@mui/material/Button';
import { FormLabelProps } from '@mui/material/FormLabel';

import { uploadObservations } from '../utils/api';

interface FileUploadProps {
	site_id: number;
	setLoading?: (isLoading: boolean) => void;
}

const FileUpload: React.FC<FileUploadProps & FormLabelProps> = ({ site_id, setLoading, ...props }) => {
	const queryClient = useQueryClient();
	const upload = useMutation(
		async (file: File) => uploadObservations(site_id, file),
		{
			onSuccess: () => queryClient.invalidateQueries(['uploads', site_id])
		}
	);

	const removeEmptyRowsAndCount = (file: File): Promise<{ cleanedFile: File; rowCount: number }> =>
		new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = () => {
				const text = reader.result as string;
				const rows = text.split('\n').filter(line => line.trim() !== '');
				const cleanedText = rows.join('\n');
				const cleanedFile = new File([new Blob([cleanedText], { type: file.type })], file.name, { type: file.type });
				resolve({ cleanedFile, rowCount: rows.length });
			};
			reader.readAsText(file);
		});
		
	const MAX_NUM_ROWS_ALLOWED = 3000;

	const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const { cleanedFile, rowCount } = await removeEmptyRowsAndCount(file);
			if (rowCount > MAX_NUM_ROWS_ALLOWED) {
				alert(`Failed to upload file: File exceeds ${MAX_NUM_ROWS_ALLOWED} rows. Please contact administrator for larger files.`);
			} else {
				upload.mutate(cleanedFile);
			}
		}
	};

	useEffect(() => {
		if (setLoading) {
			setLoading(upload.isLoading);
		}
	}, [upload.isLoading, setLoading]);
	useEffect(() => {
		if (upload.isError) {
			const error = upload.error as Error;
			alert("Failed to upload file: " + error.message);
		}
	}, [upload.isError, upload.error]);
	useEffect(() => {
		if (upload.isSuccess) {
			alert("File uploaded successfully");
		}
	}, [upload.isSuccess]);
	return (
		<Button
			variant="contained"
			component="label"
			{...props}
		>
			Upload File
			<input
				type="file"
				hidden
				accept="text/csv"
				onChange={onChange}
			/>
		</Button>
	);
};

export default FileUpload;
