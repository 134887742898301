import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { styled } from "@mui/material/styles";

import { EdgeProcessConfig, FullProcessCreate, View } from "../types";
import { isSuperuser } from "../utils/auth";
import { createProductionProcess, getView } from "../utils/api";
import { Typography } from "@mui/material";

interface Props {
  viewId: number;
  currentProcessConfig: EdgeProcessConfig;
  previousProcessConfig?: EdgeProcessConfig;
  onUpdateConfig?: (viewId: number, config: FullProcessCreate) => void;
  canModify: boolean;
}

interface EditableCellProps {
  newValue: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  newValueLabel: string;
  currentValue: number;
  prevValue: number;
  fieldType: string;
  hasBorder?: boolean;
}

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

const BorderedTableCell = styled(TableCell)`
  text-align: center;
  border-right: 2px solid #ddd;
`;

interface ConnectedProps {
  viewId: number;
  onUpdateConfig?: (viewId: number, config: FullProcessCreate) => void;
  processConfigs: { [id: number]: EdgeProcessConfig };
}

export const ConnectedProductionConfigDisplay: React.FC<ConnectedProps> = ({
  viewId,
  onUpdateConfig,
  processConfigs
}) => {
  const { data: view } = useQuery<View, Error>(
    ['view', viewId],
    () => getView(viewId)
  );
  if (!view) {
    return null;
  }
  const currentProcessConfig = processConfigs[view?.process_id ?? -1];
  const previousProcessConfig = Object.values(processConfigs).sort(
    (a, b) => new Date(b.replaced_at ?? 0).valueOf() > new Date(a.replaced_at ?? 0).valueOf() ? 1 : -1
  ).pop();

  if (!currentProcessConfig || !previousProcessConfig) {
    return null;
  }

  return (
    <ProductionConfigDisplay
      viewId={viewId}
      currentProcessConfig={currentProcessConfig}
      previousProcessConfig={previousProcessConfig}
      onUpdateConfig={onUpdateConfig}
      canModify={true}
    />
  );
}

const ProductionConfigDisplay: React.FC<Props> = ({
  viewId,
  currentProcessConfig,
  previousProcessConfig,
  onUpdateConfig,
  canModify
}) => {
  const style = !!onUpdateConfig ? { color: "violet", fontWeight: "bold" } : {};

  const [modify, setModify] = useState<boolean>(false);

  const [showPrevious, setShowPrevious] = useState<boolean>(false);

  const EditableCell: React.FC<EditableCellProps> = ({ newValue, setValue, currentValue, newValueLabel, prevValue, fieldType, hasBorder = true }) => {
    const currentValueLabel = fieldType === "lookback" ? currentValue : `${Math.round(currentValue * 100)}%`;
    const isIncreased = newValue > currentValue;
    const isDecreased = newValue < currentValue;

    let max_bound = 1;
    let min_bound = 0;
    let change_factor = 0.01;

    if (fieldType === "lookback") {
      max_bound = 30;
      min_bound = 0;
      change_factor = 1;
    } else if (fieldType === "threshold") {
      max_bound = 1;
      min_bound = 0.5;
      change_factor = 0.01;
    } else if (fieldType === "length") {
      max_bound = 0.1;
      min_bound = -0.1;
      change_factor = 0.01;
    }

    let backgroundColor = "inherit";
    if (currentValue > prevValue && showPrevious) {
      backgroundColor = "#eafaf1";
    } else if (currentValue < prevValue && showPrevious) {
      backgroundColor = "#fce4ec";
    }

    return (
      <CenteredTableCell
        style={{
          backgroundColor,
          borderRight: hasBorder ? "2px solid #ddd" : "none",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box>
            <TextField
              helperText={isIncreased || isDecreased ? currentValueLabel : null}
              value={newValueLabel}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "inherit",
                  fontWeight: isIncreased || isDecreased ? "bold" : "normal",
                  color: isIncreased ? "#3CB371" : isDecreased ? "red" : "inherit",
                  marginLeft: "8px",
                },
              }}
              FormHelperTextProps={{
                style: {
                  marginBottom: "-10px",
                  marginLeft: "-1px",
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                },
                "& .MuiFormHelperText-root": {
                  textAlign: "center",
                  width: "115%",
                },
              }}
              style={{
                width: 60,
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" marginRight={"-20px"}>
            {newValue + change_factor <= max_bound && (
              <IconButton
                size="small"
                style={{ paddingBottom: "2px" }}
                onClick={() => (Math.round(currentValue * 100) !== Math.round((newValue + change_factor) * 100) ? setValue(newValue + change_factor) : setValue(currentValue))}
              >
                <ArrowUpwardIcon style={{ fontSize: "16px" }} />
              </IconButton>
            )}
            {Math.round((newValue - change_factor) * 100) / 100 >= min_bound && (
              <IconButton
                size="small"
                style={{ paddingTop: "2px" }}
                onClick={() =>
                  newValue - change_factor < 0 && fieldType !== "length"
                    ? setValue(0)
                    : Math.round(currentValue * 100) !== Math.round((newValue - change_factor) * 100)
                    ? setValue(newValue - change_factor)
                    : setValue(currentValue)
                }
              >
                <ArrowDownwardIcon style={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
        </Box>
      </CenteredTableCell>
    );
  };

  const [vehicle_score_cutoff, setVehicleScoreCutoff] = useState<number>(currentProcessConfig.params.vehicle_score_cutoff);
  const [tire_score_cutoff, setTireScoreCutoff] = useState<number>(currentProcessConfig.params.tire_score_cutoff);
  const [tire_lookback_frames, setTireLookbackFrames] = useState<number>(Math.round(currentProcessConfig.params.tire_lookback_frames));
  const [vehicle_lookback_frames, setVehicleLookbackFrames] = useState<number>(Math.round(currentProcessConfig.params.vehicle_lookback_frames));
  const [border_cutoff_left, setBorderCutoffLeft] = useState<number>(currentProcessConfig.params.border_cutoff_left);
  const [border_cutoff_right, setBorderCutoffRight] = useState<number>(currentProcessConfig.params.border_cutoff_right);
  const [border_cutoff_top, setBorderCutoffTop] = useState<number>(currentProcessConfig.params.border_cutoff_top);
  const [border_cutoff_bottom, setBorderCutoffBottom] = useState<number>(currentProcessConfig.params.border_cutoff_bottom);
  const [straddle_cutoff_lower, setStraddleCutoffLower] = useState<number>(currentProcessConfig.params.straddle_cutoff_lower ?? 0);
  const [straddle_cutoff_upper, setStraddleCutoffUpper] = useState<number>(currentProcessConfig.params.straddle_cutoff_upper ?? 0);
  const [raised_axle_score_cutoff, setRaisedAxleScoreCutoff] = useState<number>(currentProcessConfig.params.raised_axle_score_cutoff);
  const [height_ratio_coefficients_change_factor, setHeightRatioCoefficientsChangeFactor] = useState<number>(currentProcessConfig.params.height_ratio_coefficients_change_factor ?? 0);
  const [width_coefficients_change_factor, setWidthCoefficientsChangeFactor] = useState<number>(currentProcessConfig.params.width_coefficients_change_factor ?? 0);

  const straddleLow = straddle_cutoff_lower;
  const straddleHigh = straddle_cutoff_upper;

  const canApply =
    vehicle_score_cutoff !== currentProcessConfig.params.vehicle_score_cutoff ||
    tire_score_cutoff !== currentProcessConfig.params.tire_score_cutoff ||
    tire_lookback_frames !== Math.round(currentProcessConfig.params.tire_lookback_frames) ||
    vehicle_lookback_frames !== Math.round(currentProcessConfig.params.vehicle_lookback_frames) ||
    border_cutoff_left !== currentProcessConfig.params.border_cutoff_left ||
    border_cutoff_right !== currentProcessConfig.params.border_cutoff_right ||
    border_cutoff_top !== currentProcessConfig.params.border_cutoff_top ||
    border_cutoff_bottom !== currentProcessConfig.params.border_cutoff_bottom ||
    straddle_cutoff_lower !== (currentProcessConfig.params.straddle_cutoff_lower ?? 0) ||
    straddle_cutoff_upper !== (currentProcessConfig.params.straddle_cutoff_upper ?? 0) ||
    raised_axle_score_cutoff !== currentProcessConfig.params.raised_axle_score_cutoff ||
    height_ratio_coefficients_change_factor !== (currentProcessConfig.params.height_ratio_coefficients_change_factor ?? 0) ||
    width_coefficients_change_factor !== (currentProcessConfig.params.width_coefficients_change_factor ?? 0);

  const queryClient = useQueryClient();
  const addProcess = useMutation(createProductionProcess, {
    onSuccess: () => {
      queryClient.invalidateQueries(["processes"]);
    },
  });

  const handleClickModify = () => {
    setModify(!modify);
  };

  const handleClickCancel = () => {
    setModify(!modify);
    setShowPrevious(false);
  };

  const handleClickShowPrevious = () => {
    setShowPrevious(!showPrevious);
  };

  const handleClickApply = () => {
    addProcess.mutate({
      view_id: viewId,
      vehicle_score_cutoff: vehicle_score_cutoff,
      tire_score_cutoff: tire_score_cutoff,
      tire_lookback_frames,
      vehicle_lookback_frames,
      border_cutoff_left: border_cutoff_left,
      border_cutoff_right: border_cutoff_right,
      border_cutoff_top: border_cutoff_top,
      border_cutoff_bottom: border_cutoff_bottom,
      straddle_cutoff_lower: straddle_cutoff_lower === 0 ? undefined : straddle_cutoff_lower,
      straddle_cutoff_upper: straddle_cutoff_upper === 0 ? undefined : straddle_cutoff_upper,
      raised_axle_score_cutoff: raised_axle_score_cutoff,
      height_ratio_coefficients_change_factor: height_ratio_coefficients_change_factor,
      width_coefficients_change_factor: width_coefficients_change_factor,
    });
    setModify(!modify);
    window.location.reload();
  };

  return (
    <>
      <Box marginTop={2} style={{ maxWidth: "1500px" }} marginLeft={0} display={canModify ? "block" : "flex"} gap={canModify ? 0 : 2}>
        {isSuperuser() && (
          <Box style={{ maxWidth: "900px" }}>
            <Table
              style={{
                border: "2px solid #ddd",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>View&nbsp;ID</BorderedTableCell>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>Process&nbsp;ID</BorderedTableCell>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>Timezone</BorderedTableCell>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>Processor</BorderedTableCell>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>Camera</BorderedTableCell>
                  <BorderedTableCell sx={{ fontWeight: "bold" }}>Model</BorderedTableCell>
                  <CenteredTableCell sx={{ fontWeight: "bold" }}>Direction</CenteredTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <BorderedTableCell>{viewId}</BorderedTableCell>
                  <BorderedTableCell>{currentProcessConfig.id}</BorderedTableCell>
                  <BorderedTableCell>{currentProcessConfig.time_zone}</BorderedTableCell>
                  <BorderedTableCell>{currentProcessConfig.processor_name}</BorderedTableCell>
                  <BorderedTableCell>{currentProcessConfig.camera.name}</BorderedTableCell>
                  <BorderedTableCell>{currentProcessConfig.model.name}</BorderedTableCell>
                  <CenteredTableCell>{currentProcessConfig.params.direction}</CenteredTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end">
          {!modify && canModify && (
            <Box>
              <Button size="medium" sx={{ backgroundColor: "#f0f0f0" }} onClick={handleClickModify}>
                Modify
              </Button>
            </Box>
          )}
          {modify && (
            <Box display="flex" gap={1}>
              {canApply && (
                <Button size="medium" sx={{ backgroundColor: "#f0f0f0" }} onClick={handleClickApply}>
                  Apply
                </Button>
              )}
              <Button size="medium" sx={{ backgroundColor: "#f0f0f0" }} onClick={handleClickCancel}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
        <Box marginTop={canModify ? 1 : 0}>
          <Table
            style={{
              border: "2px solid #ddd",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <TableHead>
              <TableRow>
                <BorderedTableCell colSpan={2} sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Look&nbsp;Back&nbsp;Count
                </BorderedTableCell>
                <BorderedTableCell colSpan={3} style={{ textAlign: "center", fontWeight: "bold" }}>
                  Confidence&nbsp;Thresholds
                </BorderedTableCell>
                <BorderedTableCell sx={{ fontWeight: "bold" }}>Height&nbsp;Ratio</BorderedTableCell>
                <BorderedTableCell sx={{ fontWeight: "bold" }}>Speed&nbsp;&&nbsp;Length</BorderedTableCell>
                <BorderedTableCell sx={{ fontWeight: "bold" }} colSpan={4}>
                  Bounding&nbsp;Box&nbsp;Coordinate&nbsp;Offsets
                </BorderedTableCell>
                <CenteredTableCell sx={{ fontWeight: "bold" }} colSpan={2}>
                  Straddle&nbsp;Bounds
                </CenteredTableCell>
              </TableRow>
              <TableRow>
                <CenteredTableCell rowSpan={2}>Vehicle</CenteredTableCell>
                <BorderedTableCell rowSpan={2}>Axle</BorderedTableCell>
                <BorderedTableCell rowSpan={1} style={{ borderBottom: "none", textShadow: "0 0 1px rgba(0, 0, 0, 0.5)" }}>
                  Vehicle
                </BorderedTableCell>
                <BorderedTableCell colSpan={2} style={{ textAlign: "center", textShadow: "0 0 1px rgba(0, 0, 0, 0.5)" }}>
                  Axles
                </BorderedTableCell>
                <BorderedTableCell rowSpan={2}>&nbsp;</BorderedTableCell>
                <BorderedTableCell rowSpan={2}>&nbsp;</BorderedTableCell>
                <CenteredTableCell rowSpan={2}>Upper</CenteredTableCell>
                <CenteredTableCell rowSpan={2}>Lower</CenteredTableCell>
                <CenteredTableCell rowSpan={2}>Left</CenteredTableCell>
                <BorderedTableCell rowSpan={2}>Right</BorderedTableCell>
                <CenteredTableCell rowSpan={2}>Low</CenteredTableCell>
                <CenteredTableCell rowSpan={2}>High</CenteredTableCell>
              </TableRow>
              <TableRow>
                <BorderedTableCell>&nbsp;</BorderedTableCell>
                <CenteredTableCell>Lowered</CenteredTableCell>
                <BorderedTableCell>Raised</BorderedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!modify && (
                <TableRow>
                  <CenteredTableCell style={style}>{currentProcessConfig.params.vehicle_lookback_frames}</CenteredTableCell>
                  <BorderedTableCell style={style}>{currentProcessConfig.params.tire_lookback_frames}</BorderedTableCell>
                  <CenteredTableCell style={style}>{Math.round(currentProcessConfig.params.vehicle_score_cutoff * 100)}%</CenteredTableCell>
                  <CenteredTableCell style={style}>{Math.round(currentProcessConfig.params.tire_score_cutoff * 100)}%</CenteredTableCell>
                  <BorderedTableCell style={style}>{Math.round(currentProcessConfig.params.raised_axle_score_cutoff * 100)}%</BorderedTableCell>
                  <BorderedTableCell style={style}>
                    {currentProcessConfig.params.height_ratio_coefficients_change_factor ? `${Math.round(currentProcessConfig.params.height_ratio_coefficients_change_factor * 100)}%` : "0%"}
                  </BorderedTableCell>
                  <BorderedTableCell style={style}>
                    {currentProcessConfig.params.width_coefficients_change_factor ? `${Math.round(currentProcessConfig.params.width_coefficients_change_factor * 100)}%` : "0%"}
                  </BorderedTableCell>
                  <CenteredTableCell>{Math.round(currentProcessConfig.params.border_cutoff_top * 100)}%</CenteredTableCell>
                  <CenteredTableCell>{Math.round(currentProcessConfig.params.border_cutoff_bottom * 100)}%</CenteredTableCell>
                  <CenteredTableCell>{Math.round(currentProcessConfig.params.border_cutoff_left * 100)}%</CenteredTableCell>
                  <BorderedTableCell>{Math.round(currentProcessConfig.params.border_cutoff_right * 100)}%</BorderedTableCell>
                  <CenteredTableCell>{straddleLow ? `${Math.round(straddleLow * 100)}%` : "None"}</CenteredTableCell>
                  <CenteredTableCell>{straddleHigh ? `${Math.round(straddleHigh * 100)}%` : "None"}</CenteredTableCell>
                </TableRow>
              )}
              {modify && previousProcessConfig && (
                <TableRow>
                  <EditableCell
                    newValue={vehicle_lookback_frames}
                    setValue={setVehicleLookbackFrames}
                    currentValue={currentProcessConfig.params.vehicle_lookback_frames}
                    newValueLabel={`${vehicle_lookback_frames}`}
                    prevValue={previousProcessConfig.params.vehicle_lookback_frames}
                    fieldType="lookback"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={tire_lookback_frames}
                    setValue={setTireLookbackFrames}
                    currentValue={currentProcessConfig.params.tire_lookback_frames}
                    newValueLabel={`${tire_lookback_frames}`}
                    prevValue={previousProcessConfig.params.tire_lookback_frames}
                    fieldType="lookback"
                  />
                  <EditableCell
                    newValue={vehicle_score_cutoff}
                    setValue={setVehicleScoreCutoff}
                    currentValue={currentProcessConfig.params.vehicle_score_cutoff}
                    newValueLabel={`${Math.round(vehicle_score_cutoff * 100)}%`}
                    prevValue={previousProcessConfig.params.vehicle_score_cutoff}
                    fieldType="threshold"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={tire_score_cutoff}
                    setValue={setTireScoreCutoff}
                    currentValue={currentProcessConfig.params.tire_score_cutoff}
                    newValueLabel={`${Math.round(tire_score_cutoff * 100)}%`}
                    prevValue={previousProcessConfig.params.tire_score_cutoff}
                    fieldType="threshold"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={raised_axle_score_cutoff}
                    setValue={setRaisedAxleScoreCutoff}
                    currentValue={currentProcessConfig.params.raised_axle_score_cutoff}
                    newValueLabel={`${Math.round(raised_axle_score_cutoff * 100)}%`}
                    prevValue={previousProcessConfig.params.raised_axle_score_cutoff}
                    fieldType="threshold"
                  />
                  <EditableCell
                    newValue={height_ratio_coefficients_change_factor}
                    setValue={setHeightRatioCoefficientsChangeFactor}
                    currentValue={currentProcessConfig.params.height_ratio_coefficients_change_factor ?? 0}
                    newValueLabel={`${Math.round(height_ratio_coefficients_change_factor * 100)}%`}
                    prevValue={previousProcessConfig.params.height_ratio_coefficients_change_factor ?? 0}
                    fieldType="length"
                  />
                  <EditableCell
                    newValue={width_coefficients_change_factor}
                    setValue={setWidthCoefficientsChangeFactor}
                    currentValue={currentProcessConfig.params.width_coefficients_change_factor ?? 0}
                    newValueLabel={`${Math.round(width_coefficients_change_factor * 100)}%`}
                    prevValue={previousProcessConfig.params.width_coefficients_change_factor ?? 0}
                    fieldType="length"
                  />
                  <EditableCell
                    newValue={border_cutoff_top}
                    setValue={setBorderCutoffTop}
                    currentValue={currentProcessConfig.params.border_cutoff_top}
                    newValueLabel={`${Math.round(border_cutoff_top * 100)}%`}
                    prevValue={previousProcessConfig.params.border_cutoff_top}
                    fieldType="percent"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={border_cutoff_bottom}
                    setValue={setBorderCutoffBottom}
                    currentValue={currentProcessConfig.params.border_cutoff_bottom}
                    newValueLabel={`${Math.round(border_cutoff_bottom * 100)}%`}
                    prevValue={previousProcessConfig.params.border_cutoff_bottom}
                    fieldType="percent"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={border_cutoff_left}
                    setValue={setBorderCutoffLeft}
                    currentValue={currentProcessConfig.params.border_cutoff_left}
                    newValueLabel={`${Math.round(border_cutoff_left * 100)}%`}
                    prevValue={previousProcessConfig.params.border_cutoff_left}
                    fieldType="percent"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={border_cutoff_right}
                    setValue={setBorderCutoffRight}
                    currentValue={currentProcessConfig.params.border_cutoff_right}
                    newValueLabel={`${Math.round(border_cutoff_right * 100)}%`}
                    prevValue={previousProcessConfig.params.border_cutoff_right}
                    fieldType="percent"
                  />
                  <EditableCell
                    newValue={straddleLow}
                    setValue={setStraddleCutoffLower}
                    currentValue={currentProcessConfig.params.straddle_cutoff_lower ?? 0}
                    newValueLabel={`${Math.round(straddleLow * 100)}%`}
                    prevValue={previousProcessConfig.params.straddle_cutoff_lower ?? 0}
                    fieldType="percent"
                    hasBorder={false}
                  />
                  <EditableCell
                    newValue={straddleHigh}
                    setValue={setStraddleCutoffUpper}
                    currentValue={currentProcessConfig.params.straddle_cutoff_upper ?? 0}
                    newValueLabel={`${Math.round(straddleHigh * 100)}%`}
                    prevValue={previousProcessConfig.params.straddle_cutoff_upper ?? 0}
                    fieldType="percent"
                    hasBorder={false}
                  />
                </TableRow>
              )}
              {modify && showPrevious && previousProcessConfig && (
                <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                  <CenteredTableCell>{previousProcessConfig.params.vehicle_lookback_frames}</CenteredTableCell>
                  <BorderedTableCell>{previousProcessConfig.params.tire_lookback_frames}</BorderedTableCell>
                  <CenteredTableCell>{Math.round(previousProcessConfig.params.vehicle_score_cutoff * 100)}%</CenteredTableCell>
                  <CenteredTableCell>{Math.round(previousProcessConfig.params.tire_score_cutoff * 100)}%</CenteredTableCell>
                  <BorderedTableCell>{Math.round(previousProcessConfig.params.raised_axle_score_cutoff * 100)}%</BorderedTableCell>
                  <BorderedTableCell>
                    {previousProcessConfig.params.height_ratio_coefficients_change_factor ? `${Math.round(previousProcessConfig.params.height_ratio_coefficients_change_factor * 100)}%` : ""}
                  </BorderedTableCell>
                  <BorderedTableCell>
                    {previousProcessConfig.params.width_coefficients_change_factor ? `${Math.round(previousProcessConfig.params.width_coefficients_change_factor * 100)}%` : ""}
                  </BorderedTableCell>
                  <CenteredTableCell>{Math.round(previousProcessConfig.params.border_cutoff_top * 100)}%</CenteredTableCell>
                  <CenteredTableCell>{Math.round(previousProcessConfig.params.border_cutoff_bottom * 100)}%</CenteredTableCell>
                  <CenteredTableCell>{Math.round(previousProcessConfig.params.border_cutoff_left * 100)}%</CenteredTableCell>
                  <BorderedTableCell>{Math.round(previousProcessConfig.params.border_cutoff_right * 100)}%</BorderedTableCell>
                  <CenteredTableCell>{straddleLow ? `${Math.round(straddleLow * 100)}%` : "None"}</CenteredTableCell>
                  <CenteredTableCell>{straddleHigh ? `${Math.round(straddleHigh * 100)}%` : "None"}</CenteredTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        {modify && !showPrevious && (
          <Button
            size="small"
            onClick={handleClickShowPrevious}
            endIcon={<ArrowDropDownIcon />}
            sx={{
              backgroundColor: "#f0f0f0",
              color: "black",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="body2">Show Previous Values</Typography>
          </Button>
        )}
        {modify && showPrevious && (
          <Button
            size="small"
            onClick={handleClickShowPrevious}
            endIcon={<ArrowDropUpIcon />}
            sx={{
              color: "black",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="body2">Hide Previous Values</Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

export default ProductionConfigDisplay;
