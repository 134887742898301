import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import Adjust from '@mui/icons-material/Adjust';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import VideoPlayer from '../components/VideoPlayer';
import KeyFrameViewer from "../components/KeyFrameViewer";
import Header from '../views/Header';
import VerifyDetection from '../components/VerifyDetection';
import ToggleVehicleStats from '../components/ToggleVehicleStats';

import { DetectionDetail, EdgeProcessConfig } from '../types';
import { formatDate, scaleSpeed, scaleDistance } from '../utils';
import { getProcessConfigsBySite } from '../utils/api';
import { isSuperuser } from '../utils/auth';
import { ASSET_URL } from '../config';

function getPercentageColor(percentage?: number): string {
  if (typeof percentage !== 'number') {
    return 'black';
  }
  if (percentage <= 0.9) {
    return 'lightBlue';
  }
  if (percentage <= 0.95) {
    return '#FFD580';
  }
  return 'lightGreen';
}

interface Props {
  detection: DetectionDetail;
  return_url: string;
}

const DetectionPage: React.FC<Props> = ({ detection, return_url }) => {
  const { data: configs } = useQuery<EdgeProcessConfig[], Error>(['site-config', detection.site_id], () => getProcessConfigsBySite(detection.site_id));
  const processConfig = configs?.find(c => c.id === detection.process_id);
  const captureVideo = processConfig?.capture_video ?? true;
  const captureRawVideo = processConfig?.capture_raw_video ?? false;

  const navigate = useNavigate();
  const [frame, setFrame] = React.useState<number>(0);
  const handleClickThumbnail = (e: React.MouseEvent<HTMLImageElement>) => {
    if (detection.thumbnail_frames.length === 0) {
      return;
    }
    const partWidth = (e.target as any).clientWidth / detection.thumbnail_frames.length;
    const index = Math.min(Math.max(0, Math.floor(e.clientX / partWidth)), detection.thumbnail_frames.length - 1);
    setFrame(detection.thumbnail_frames[detection.thumbnail_frames.length - 1 - index] - detection.start_frame);
  };

  const getDownloadFileName: (time: number) => string = (time) => {
    return (detection.site_name + '-' + detection.camera_name + '-' + detection.vehicle_number + '-' + formatDate(detection.start_time).replace(' ', '-') + '-' + time).replace(/ /g, '').replace(/:/g, '').replace(/\./g, '') + '.jpg';
  };

  const duration = Math.ceil((detection.end_time.valueOf() - detection.start_time.valueOf()) / 1000);

  const navigateBack = () => {
    if (detection.previous_id) {
      navigate(`/detections/${detection.previous_id}?return=${encodeURIComponent(return_url)}`);
    }
  };

  const navigateForward = () => {
    if (detection.next_id) {
      navigate(`/detections/${detection.next_id}?return=${encodeURIComponent(return_url)}`);
    }
  };

  const navigateCurrent = () => {
    navigate(`/detections/${detection.current_id}?return=${encodeURIComponent(return_url)}`);
  };

  return (
    <React.Fragment>
      <Header>
        <Link to={return_url} style={{ color: 'inherit' }}>{detection.site_name}</Link>
        &nbsp;&mdash;&nbsp;
        Vehicle: { detection.vehicle_number }
        &nbsp;&mdash;&nbsp;
        { detection.camera_name }
        <IconButton disabled={!detection.previous_id} onClick={navigateBack}><NavigateBefore /></IconButton>
        <IconButton color={detection.id === detection.current_id ? 'warning' : 'default' } onClick={detection.id !== detection.current_id ? navigateCurrent : undefined}><Adjust /></IconButton>
        <IconButton disabled={!detection.next_id} onClick={navigateForward}><NavigateNext /></IconButton>
      </Header>
      <Grid container>
        <Grid item sx={{ m: 2 }}>
	  <Card>
	    <CardHeader title="Details" />
	    <CardContent>
              <Typography>Vehicle Class: {detection.vehicle_class}</Typography>
              <Typography>Toll Class #: {detection.vehicle_subclass ?? ''}</Typography>
              <Typography>Start: {formatDate(detection.start_time, 2)}</Typography>
              <Typography>End: {formatDate(detection.end_time, 2)}</Typography>
              <Typography>Duration: {duration} second{duration > 1 && 's'}</Typography>
              <Typography>Speed: {scaleSpeed(detection.measurement_scale, detection.speed)}{'metric' === detection.measurement_scale ? 'km/h' : 'mph'}</Typography>
              <Typography>Length: {scaleDistance(detection.measurement_scale, detection.length)}{'metric' === detection.measurement_scale ? 'm' : 'in'}</Typography>
              <Typography>Height: {scaleDistance(detection.measurement_scale, detection.height)}{'metric' === detection.measurement_scale ? 'm' : 'in'}</Typography>
              <Typography>Straddle: {detection.straddle ?? 'None'}</Typography>
              <Typography># Total Axles:{detection.num_tires}</Typography>
              {isSuperuser() && <Typography># Raised Axles:{detection.num_raised_tires}</Typography>}
              <Typography># Raised Axles{isSuperuser() && ' (ML)'}:{detection.num_raised_tires_ml}</Typography>
              {isSuperuser() && <Typography>Raised Axle Indices: {detection.num_raised_tires === 0 ? 'None' : detection.raised_tires.join(', ')}</Typography>}
              <Typography>Raised Axle Indices {isSuperuser() && ' (ML)'}: {!detection.raised_tires_ml || detection.num_raised_tires_ml === 0 ? 'None' : detection.raised_tires_ml?.join(', ')}</Typography>
              <Typography>Minimum Vehicle Score: {detection.minimum_vehicle_score ? detection.minimum_vehicle_score.toLocaleString(undefined, { maximumFractionDigits: 3 }) : 'Unknown'}</Typography>
              <Typography>Minimum Axle Score: {detection.minimum_tire_score ? detection.minimum_tire_score.toLocaleString(undefined, { maximumFractionDigits: 3 }) : 'Unknown'}</Typography>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography>Vehicle:</Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-around'}}>
                  <Typography>Min Mode: <span style={{ color: getPercentageColor(detection.vehicle_minimum_mode) }}>{detection.vehicle_minimum_mode ? (detection.vehicle_minimum_mode * 100).toFixed(0) : ''}</span></Typography>
                  <Typography>Min Score: <span style={{ color: getPercentageColor(detection.minimum_vehicle_score) }}>{detection.minimum_vehicle_score ? (detection.minimum_vehicle_score * 100).toFixed(2) : ''}</span></Typography>
                  <Typography>Lowest Max: <span style={{ color: getPercentageColor(detection.vehicle_lowest_maximum) }}>{detection.vehicle_lowest_maximum ? (detection.vehicle_lowest_maximum * 100).toFixed(2) : ''}</span></Typography>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography>Axles:</Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-around'}}>
                  <Typography>Min Mode: <span style={{ color: getPercentageColor(detection.axle_minimum_mode) }}>{detection.axle_minimum_mode ? (detection.axle_minimum_mode * 100).toFixed(0) : ''}</span></Typography>
                  <Typography>Min Score: <span style={{ color: getPercentageColor(detection.minimum_tire_score) }}>{detection.minimum_tire_score ? (detection.minimum_tire_score * 100).toFixed(2) : ''}</span></Typography>
                  <Typography>Lowest Max: <span style={{ color: getPercentageColor(detection.axle_lowest_maximum) }}>{detection.axle_lowest_maximum ? (detection.axle_lowest_maximum * 100).toFixed(2) : ''}</span></Typography>
                </div>
              </div>
	    </CardContent>
          </Card>
          <Box sx={{mt: 2}}>
          <VerifyDetection detection={detection} />
          </Box>
        </Grid>
        <Grid item sx={{ m: 1 }}>
          <ToggleVehicleStats detection={detection} />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > :not(style)': {
            m: 1
          }
        }}>
        {captureVideo ?
          <VideoPlayer
            url={`${ASSET_URL}/${detection.process_id}/snippet-${detection.vehicle_number}.mp4`}
            rawUrl={`${ASSET_URL}/${detection.process_id}/raw-snippet-${detection.vehicle_number}.mp4`}
            trainingUrl={detection.has_training_images ? `${ASSET_URL}/${detection.process_id}/training-${detection.vehicle_number}.tgz` : undefined}
            fps={30}
            frame={frame}
            getDownloadFileName={getDownloadFileName}
          /> : <KeyFrameViewer detection={detection} />}
        {captureRawVideo && <div>
          <a href={`${ASSET_URL}/${detection.process_id}/raw-snippet-${detection.vehicle_number}.mp4`} download="{detection.site_name}-{detection.camera_name}-{detection.vehicle_number}-{formatDate(detection.start_time}.mp4">Download Raw Video</a>
        </div>}
        <div>
          <img onClick={handleClickThumbnail} alt="" style={{ maxWidth: 640, maxHeight: 360, cursor: 'pointer' }} src={`${ASSET_URL}/${detection.process_id}/thumbnail-${detection.vehicle_number}.png`} />
        </div>
      </Box>
    </React.Fragment>
  );
};

export default DetectionPage;
