import React, { useState }  from 'react';
import { Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { Detection } from '../types';
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Props {
  detection: Detection;
}

const VehicleStats: React.FC<Props> = ({ detection }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!detection.statistics) {
    return null;
  }
  const tireStats = detection.statistics?.tires;
  const vehicleStats= detection.statistics;
  const numberOfTires = detection.num_tires;
  const numOfTiresValue = Array.from({ length: numberOfTires }, (_, index) => index);
  const MAX_TIRE_ROWS_TO_DISPLAY = 2;

  function highestVal(a: number, b: number, c: number) {
    return a >= b && a >= c;
  }
  
  return (
    <Card>
    <CardContent style={{ padding: '0px' }}> {/* Adjust padding here */}
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow>          
                <TableCell align="center" style={{ fontSize: 'small' }}>No. of Frames
                  <Typography align="center" style={{fontSize:'small', marginTop:"10px", padding:'0px', color:'black'}}> {vehicleStats?.frame_count}</Typography>
                </TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Mean</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Median</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Mode</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Total Number of Detections</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Absolute Highest</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Low
                  <Typography align="center" style={{fontSize:'small', padding:'0px', color:'black'}}> 90 &lt; </Typography>
                </TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>Medium
                  <Typography align="center" style={{fontSize:'small', padding:'0px', color:'black'}}> 90 - 95</Typography>
                </TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>High
                  <Typography align="center" style={{fontSize:'small', padding:'0px', color:'black'}}> 95 &gt; </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Vehicle</TableCell>
                <TableCell align="center">{vehicleStats?.mean_score?(vehicleStats?.mean_score* 100).toFixed(2) : ''}</TableCell>
                <TableCell align="center">{vehicleStats?.median_score?(vehicleStats?.median_score* 100).toFixed(2) : ''}</TableCell>           
                <TableCell align="center">{vehicleStats?.distribution && vehicleStats.distribution.length > 0? 99-(vehicleStats.distribution.length - 1 - vehicleStats.distribution.reverse().indexOf(Math.max(...vehicleStats.distribution))) : ''}</TableCell>
                <TableCell align="center">{vehicleStats?.detection_count}</TableCell>
                <TableCell align="center">{vehicleStats?.maximum_score?(vehicleStats?.maximum_score* 100).toFixed(2) : ''}</TableCell>  

                <TableCell align="center" style={{ color: vehicleStats?.distribution ? (highestVal(vehicleStats.distribution.slice(0, -11).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(-11, -5).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(-5).reduce((a, b) => a + b, 0)) ? 'blue' : '') : '' }}>
                  {vehicleStats?.distribution ? (vehicleStats?.distribution?.slice(0, -11).reduce((a, b) => a + b, 0) / (vehicleStats?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
              </TableCell>
              <TableCell align="center" style={{ color: vehicleStats?.distribution ? (highestVal(vehicleStats.distribution.slice(-11, -5).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(0, -11).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(-5).reduce((a, b) => a + b, 0)) ? 'blue' : '') : '' }}>
                {vehicleStats?.distribution ? (vehicleStats?.distribution?.slice(-11, -5).reduce((a, b) => a + b, 0) / (vehicleStats?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
              </TableCell>
              <TableCell align="center" style={{ color: vehicleStats?.distribution ? (highestVal(vehicleStats.distribution.slice(-5).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(0, -11).reduce((a, b) => a + b, 0), vehicleStats.distribution.slice(-11, -5).reduce((a, b) => a + b, 0)) ? 'blue' : '') : '' }}>
                {vehicleStats?.distribution ? (vehicleStats?.distribution?.slice(-5).reduce((a, b) => a + b, 0) / (vehicleStats?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
              </TableCell>


              </TableRow>
              {numOfTiresValue.slice(0, isExpanded ? numOfTiresValue.length : MAX_TIRE_ROWS_TO_DISPLAY).map((tireNumValue) => (<TableRow key={tireNumValue}>
                <TableCell style={{ fontSize: 'small' }} align="center">Axle {tireNumValue+1}</TableCell>
                <TableCell align="center">{tireStats?.[tireNumValue]?.mean_score ? (tireStats[tireNumValue].mean_score * 100).toFixed(2) : ''}</TableCell>
                <TableCell align="center">{tireStats?.[tireNumValue]?.median_score ? (tireStats[tireNumValue].median_score * 100).toFixed(2) : ''}</TableCell>
                <TableCell align="center">{tireStats?.[tireNumValue]?.distribution && tireStats[tireNumValue].distribution.length > 0 ?  99-(tireStats[tireNumValue].distribution.length - 1 - tireStats[tireNumValue].distribution.reverse().indexOf(Math.max(...tireStats[tireNumValue].distribution)))
                : ''}</TableCell>
                <TableCell align="center">{tireStats?.[tireNumValue]?.detection_count}</TableCell>
                <TableCell align="center"> {tireStats?.[tireNumValue]?.maximum_score ? (tireStats[tireNumValue].maximum_score * 100).toFixed(2) : ''}</TableCell>
                  
              {/* identifying, comparing (and changing to blue if highest), and dividing by totalCount */}
                <TableCell align="center" style={{padding:'0px', color: highestVal(tireStats?.[tireNumValue]?.distribution?.slice(0, -11).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(-11, -5).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(-5).reduce((a, b) => a + b, 0) || 0) ? 'blue' : '' }}> 
                {tireStats?.[tireNumValue]?.distribution ? (tireStats[tireNumValue].distribution.slice(0, -11).reduce((a, b) => a + b, 0) / (tireStats?.[tireNumValue]?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
                </TableCell>

                <TableCell align="center" style={{padding:'0px', color: highestVal(tireStats?.[tireNumValue]?.distribution?.slice(-11, -5).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(0, -11).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(-5).reduce((a, b) => a + b, 0) || 0) ? 'blue' : '' }}> 
                {tireStats?.[tireNumValue]?.distribution ? (tireStats[tireNumValue].distribution.slice(-11, -5).reduce((a, b) => a + b, 0) / (tireStats?.[tireNumValue]?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
                </TableCell>

                <TableCell align="center" style={{ padding:'0px',color: highestVal(tireStats?.[tireNumValue]?.distribution?.slice(-5).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(0, -11).reduce((a, b) => a + b, 0) || 0, tireStats?.[tireNumValue]?.distribution?.slice(-11, -5).reduce((a, b) => a + b, 0) || 0) ? 'blue' : '' }}> 
                {tireStats?.[tireNumValue]?.distribution ? (tireStats[tireNumValue].distribution.slice(-5).reduce((a, b) => a + b, 0) / (tireStats?.[tireNumValue]?.distribution?.reduce((a, b) => a + b, 0) || 0)).toFixed(2) : ''}
                </TableCell>

                </TableRow>
              ))} 
            </TableBody>
          </Table>
        </TableContainer>
        {numOfTiresValue.length > MAX_TIRE_ROWS_TO_DISPLAY && <Button
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
            endIcon={isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            sx={{
              backgroundColor: "#f0f0f0",
              color: "black",
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              marginTop: '1px'
            }}
          >
            <Typography variant="body2">{isExpanded ? 'Show Less' : 'Show More'}</Typography>
          </Button>}
      </CardContent>
    </Card>
  );
};

export default VehicleStats;
