import React, { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import ProcessList from '../components/ProcessList';
import CameraSelect from '../components/CameraSelect';
import ModelSelect from '../components/ModelSelect';
import ProcessorSelect from '../components/ProcessorSelect';
import { View, EdgeProcessConfig, ProcessWithConfig } from '../types';

import { createFullProcess, getViews, getProcessConfigsBySite } from '../utils/api';

interface ProcessesProps {
	currentSiteId: number
}

function isFloatInputValid(x: string): boolean {
  return x === '' || x === '.' || !isNaN(parseFloat(x));
}

const Processes: React.FC<ProcessesProps> = ({ currentSiteId }) => {
  const params = useParams();
  const siteId = currentSiteId || Number(params.siteId);

  const { data: views } = useQuery<View[], Error>(['views', siteId, 'live'], () => getViews(siteId, 'live'));
  const { data: configs } = useQuery<EdgeProcessConfig[], Error>(['site-config', siteId], () => getProcessConfigsBySite(siteId) );
  const processConfigs: {[process_id: number]: EdgeProcessConfig } = (configs ?? []).reduce((pc, c) => ({ ...pc, [c.id]: c }), {});

	const [adding, setAdding] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
	const [processor_id, setProcessorId] = useState<number>(0);
	const [camera_id, setCameraId] = useState<number>(0);
	const [model_id, setModelId] = useState<number>(0);
  const [vehicle_score_cutoff, setVehicleScoreCutoff] = useState<string>('0.85');
  const [tire_score_cutoff, setTireScoreCutoff] = useState<string>('0.85');
  // const [dimension_cutoff, setDimensionCutoff] = useState<string>('0.025');
  const dimension_cutoff = '0.025';
  const [tire_lookback_frames, setTireLookbackFrames] = useState<number>(3);
  const [vehicle_lookback_frames, setVehicleLookbackFrames] = useState<number>(3);
  const [direction, setDirection] = useState<'LTR' | 'RTL'>('LTR');
  // const [error_margin, setErrorMargin] = useState<string>('0.025');
  const error_margin = '0.025';
  const [border_cutoff_left, setBorderCutoffLeft] = useState<string>('0.025');
  const [border_cutoff_right, setBorderCutoffRight] = useState<string>('0.025');
  const [border_cutoff_top, setBorderCutoffTop] = useState<string>('0');
  const [border_cutoff_bottom, setBorderCutoffBottom] = useState<string>('0');
  const [straddle_cutoff_lower, setStraddleCutoffLower] = useState<string>('');
  const [straddle_cutoff_upper, setStraddleCutoffUpper] = useState<string>('');
  // const [maximum_aspect_ratio, setMaximumAspectRatio] = useState<string>('2');
  const maximum_aspect_ratio = '2';
  // const [raised_axle_offset, setRaisedAxleOffset] = useState<string>('0.025');
  const raised_axle_offset = '0.025';
  const [raised_axle_limit, setRaisedAxleLimit] = useState<string>('0.1');
  const [raised_axle_score_cutoff, setRaisedAxleScoreCutoff] = useState<string>('0.85');
  const [ml_raised_ratio, setMlRaisedRatio] = useState<string>('0.5');
  // const [max_join_gap_ms, setMaxJoinGapMs] = useState<number>(0);
  const max_join_gap_ms = 0;
  const [capture_video, setCaptureVideo] = useState<boolean>(true);
  const [capture_raw_video, setCaptureRawVideo] = useState<boolean>(false);
  const [capture_training_images, setCaptureTrainingImages] = useState<boolean>(false);
  // const [meters_per_width, setMetersPerWidth] = useState<string>('1.0');
  const meters_per_width = '1.0';
  // const [perspective_ratio, setPerspectiveRatio] = useState<string>('0.0');
  const perspective_ratio = '0.0';
  const [height_ratio, setHeightRatio] = useState<string>('1.0');
  const [width_coefficients, setWidthCoefficients] = useState<[string, string, string, string]>(['0', '1', '0', '0']);
  const [height_coefficients, setHeightCoefficients] = useState<[string, string, string, string]>(['0', '1', '0', '0']);

	const handleClickAdd = () => {
		setAdding(true);
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
	const handleChangeProcessorId = (event: React.ChangeEvent<HTMLInputElement>) => setProcessorId(+event.target.value);
	const handleChangeCameraId = (event: React.ChangeEvent<HTMLInputElement>) => setCameraId(+event.target.value);
	const handleChangeModelId = (event: React.ChangeEvent<HTMLInputElement>) => setModelId(+event.target.value);
  const handleChangeVehicleScoreCutoff = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setVehicleScoreCutoff(event.target.value);
  const handleChangeTireScoreCutoff = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setTireScoreCutoff(event.target.value);
  //const handleChangeDimensionCutoff = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setDimensionCutoff(event.target.value);
  const handleChangeTireLookbackFrames = (event: React.ChangeEvent<HTMLInputElement>) => setTireLookbackFrames(+event.target.value);
  const handleChangeVehicleLookbackFrames = (event: React.ChangeEvent<HTMLInputElement>) => setVehicleLookbackFrames(+event.target.value);
  const handleChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => setDirection(event.target.value === 'LTR' ? 'LTR' : 'RTL');
  //const handleChangeErrorMargin = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setErrorMargin(event.target.value);
  const handleChangeBorderCutoffLeft = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setBorderCutoffLeft(event.target.value);
  const handleChangeBorderCutoffRight = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setBorderCutoffRight(event.target.value);
  const handleChangeBorderCutoffTop = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setBorderCutoffTop(event.target.value);
  const handleChangeBorderCutoffBottom = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setBorderCutoffBottom(event.target.value);
  const handleChangeStraddleCutoffLower = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setStraddleCutoffLower(event.target.value);
  const handleChangeStraddleCutoffUpper = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setStraddleCutoffUpper(event.target.value);
  //const handleChangeMaximumAspectRatio = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setMaximumAspectRatio(event.target.value);
  //const handleChangeRaisedAxleOffset = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setRaisedAxleOffset(event.target.value);
 const handleChangeRaisedAxleLimit = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setRaisedAxleLimit(event.target.value);
  const handleChangeRaisedAxleScoreCutoff = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setRaisedAxleScoreCutoff(event.target.value);
  const handleChangeMlRaisedRatio = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setMlRaisedRatio(event.target.value);
  //const handleChangeMaxJoinGapMs = (event: React.ChangeEvent<HTMLInputElement>) => setMaxJoinGapMs(+event.target.value);
  const handleToggleCaptureVideo = (event: React.ChangeEvent<HTMLInputElement>) => setCaptureVideo(x => !x);
  const handleToggleCaptureRawVideo = (event: React.ChangeEvent<HTMLInputElement>) => setCaptureRawVideo(x => !x);
  const handleToggleCaptureTrainingImages = (event: React.ChangeEvent<HTMLInputElement>) => setCaptureTrainingImages(x => !x);
  //const handleChangeMetersPerWidth = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setMetersPerWidth(event.target.value);
  //const handleChangePerspectiveRatio = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setPerspectiveRatio(event.target.value);
  const handleChangeHeightRatio = (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setHeightRatio(event.target.value);
  const handleChangeWidthCoefficient = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setWidthCoefficients(Object.assign([], width_coefficients, {[index]: event.target.value}));
  const handleChangeHeightCoefficient = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => isFloatInputValid(event.target.value) && setHeightCoefficients(Object.assign([], height_coefficients, {[index]: event.target.value}));

  const view = views?.find(view => view.camera_id === camera_id);
  const processConfig = processConfigs[view?.process_id ?? -1];

  const handleClickAutoPopulate = () => {
    setVehicleScoreCutoff(String(processConfig.params.vehicle_score_cutoff));
    setTireScoreCutoff(String(processConfig.params.tire_score_cutoff));
    setTireLookbackFrames(processConfig.params.tire_lookback_frames);
    setVehicleLookbackFrames(processConfig.params.vehicle_lookback_frames);
    setBorderCutoffLeft(String(processConfig.params.border_cutoff_left));
    setBorderCutoffRight(String(processConfig.params.border_cutoff_right));
    setBorderCutoffTop(String(processConfig.params.border_cutoff_top));
    setBorderCutoffBottom(String(processConfig.params.border_cutoff_bottom));
    setStraddleCutoffLower(String(processConfig.params.straddle_cutoff_lower));
    setRaisedAxleScoreCutoff(String(processConfig.params.raised_axle_score_cutoff));
    setHeightRatio(String(processConfig.params.height_ratio));
    if(processConfig.params.width_coefficients){
        const currentWidthCoefficients: [string, string, string, string] = processConfig.params.width_coefficients.map(value => String(value)) as [string, string, string, string];
        setWidthCoefficients(currentWidthCoefficients);
    }
    if(processConfig.params.height_coefficients){
      const currentHeightCoefficients: [string, string, string, string] = processConfig.params.height_coefficients.map(value => String(value)) as [string, string, string, string];
      setHeightCoefficients(currentHeightCoefficients);
    }
  }

  const handleCreateFromExisting = (process: ProcessWithConfig) => {
    setAdding(true);
    setName(process.name);
    setProcessorId(process.processor_id);
    setCameraId(process.camera_id);
    setModelId(process.model_id);
    setVehicleScoreCutoff(String(process.config.vehicle_score_cutoff));
    setTireScoreCutoff(String(process.config.tire_score_cutoff));
    setTireLookbackFrames(process.config.tire_lookback_frames);
    setVehicleLookbackFrames(process.config.vehicle_lookback_frames);
    setDirection(process.config.direction);
    setBorderCutoffLeft(String(process.config.border_cutoff_left));
    setBorderCutoffRight(String(process.config.border_cutoff_right));
    setBorderCutoffTop(String(process.config.border_cutoff_top));
    setBorderCutoffBottom(String(process.config.border_cutoff_bottom));
    setStraddleCutoffLower(String(process.config.straddle_cutoff_lower ?? ""));
    setStraddleCutoffUpper(String(process.config.straddle_cutoff_upper ?? ""));
    setRaisedAxleScoreCutoff(String(process.config.raised_axle_score_cutoff));
    setRaisedAxleLimit(String(process.config.raised_axle_limit));
    setHeightRatio(String(process.config.height_ratio ?? "1.0"));
    if (process.config.width_coefficients){
        const currentWidthCoefficients: [string, string, string, string] = process.config.width_coefficients.map(value => String(value)) as [string, string, string, string];
        setWidthCoefficients(currentWidthCoefficients);
    } else {
        setWidthCoefficients(["0", "1", "0", "0"]);
    }
    if (process.config.height_coefficients){
      const currentHeightCoefficients: [string, string, string, string] = process.config.height_coefficients.map(value => String(value)) as [string, string, string, string];
      setHeightCoefficients(currentHeightCoefficients);
    } else {
        setHeightCoefficients(["0", "1", "0", "0"]);
    }
    setCaptureVideo(process.capture_video);
    setCaptureRawVideo(process.capture_raw_video);
    setCaptureTrainingImages(process.capture_training_images);
  };

	const queryClient = useQueryClient();
	const addProcess = useMutation(createFullProcess, {
		onSuccess: () => {
			queryClient.invalidateQueries(['processes', siteId]);
		}
	});

	const handleCreate = () => {
		addProcess.mutate({
      name,
      view_type: 'live',
      processor_id,
      camera_id,
      model_id,
      site_id: siteId,
      vehicle_score_cutoff: parseFloat(vehicle_score_cutoff),
      tire_score_cutoff: parseFloat(tire_score_cutoff),
      dimension_cutoff: parseFloat(dimension_cutoff),
      tire_lookback_frames,
      vehicle_lookback_frames,
      direction,
      error_margin: parseFloat(error_margin),
      border_cutoff_left: parseFloat(border_cutoff_left),
      border_cutoff_right: parseFloat(border_cutoff_right),
      border_cutoff_top: parseFloat(border_cutoff_top),
      border_cutoff_bottom: parseFloat(border_cutoff_bottom),
      straddle_cutoff_lower: straddle_cutoff_lower === '' ? undefined : parseFloat(straddle_cutoff_lower),
      straddle_cutoff_upper: straddle_cutoff_upper === '' ? undefined : parseFloat(straddle_cutoff_upper),
      maximum_aspect_ratio: parseFloat(maximum_aspect_ratio),
      raised_axle_offset: parseFloat(raised_axle_offset),
      raised_axle_limit: parseFloat(raised_axle_limit),
      raised_axle_score_cutoff: parseFloat(raised_axle_score_cutoff),
      ml_raised_ratio: parseFloat(ml_raised_ratio),
      max_join_gap_ms: max_join_gap_ms,
      capture_video,
      capture_raw_video,
      capture_training_images,
      meters_per_width: parseFloat(meters_per_width),
      perspective_ratio: parseFloat(perspective_ratio),
      height_ratio: parseFloat(height_ratio),
      width_coefficients: width_coefficients.every(x => !x) ? undefined : width_coefficients.map(x => parseFloat(x) || 0),
      height_coefficients: height_coefficients.every(x => !x) ? undefined : height_coefficients.map(x => parseFloat(x) || 0),
    });
		setAdding(false);
	}
  const style = {
    color: 'blue',
  };

	return (
    <Card>
      <CardHeader
        title={adding ? "Create Process" : "Processes"}
        action={
					!adding && <IconButton aria-label="Create Process" onClick={handleClickAdd}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
				{adding ?
        <>
        {!!camera_id && <Button onClick={handleClickAutoPopulate} variant={'contained'} sx={{ml: 1}}>Current Configs</Button>}
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { m: 1, width: '20ch' }
						}}
						noValidate
						autoComplete="off"
            display="flex"
            flexDirection="column"
					>
            <Box>
              <TextField variant="standard" label="Name" onChange={handleChangeName} value={name} InputProps={{ style: style }}/>
            </Box>
            <Box display="flex">
  						<Box>
  						  <ProcessorSelect siteId={siteId} variant="standard" onChange={handleChangeProcessorId} value={processor_id || ''} InputProps={{ style: style }}/>
  						</Box>
  						<Box>
  						  <CameraSelect siteId={siteId} variant="standard" onChange={handleChangeCameraId} value={camera_id || ''} InputProps={{ style: style }}/>
  						</Box>
  						<Box>
  						  <ModelSelect variant="standard" onChange={handleChangeModelId} value={model_id || ''} InputProps={{ style: style }}/>
  						</Box>
            </Box>
            <Box display="flex">
						  <Box>
                <TextField variant="standard" label="Vehicle Score Cutoff" onChange={handleChangeVehicleScoreCutoff} value={vehicle_score_cutoff} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label="Axle Score Cutoff" onChange={handleChangeTireScoreCutoff} value={tire_score_cutoff} InputProps={{ style: style }}/>
              </Box>
              <Box>
                <TextField variant="standard" label="Raised Axle Score Cutoff" onChange={handleChangeRaisedAxleScoreCutoff} value={raised_axle_score_cutoff} InputProps={{ style: style }}/>
              </Box>
              <Box>
                <TextField variant="standard" label="Raised Axle Ratio" onChange={handleChangeMlRaisedRatio} value={ml_raised_ratio} InputProps={{ style }} />
              </Box>
              <Box>
                <TextField variant="standard" label="Raised Axle Limit" onChange={handleChangeRaisedAxleLimit} value={raised_axle_limit} InputProps={{ style }} />
              </Box>
            </Box>
            <Box display="flex">
            <Box>
                <TextField variant="standard" label="Vehicle Lookback Frames" onChange={handleChangeVehicleLookbackFrames} value={vehicle_lookback_frames} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label="Axle Lookback Frames" onChange={handleChangeTireLookbackFrames} value={tire_lookback_frames} InputProps={{ style: style }}/>
              </Box>
            </Box>
            <Box display="flex">
						  <Box>
                <TextField variant="standard" label="Top Border" onChange={handleChangeBorderCutoffTop} value={border_cutoff_top} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label="Bottom Border" onChange={handleChangeBorderCutoffBottom} value={border_cutoff_bottom} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label="Left Border" onChange={handleChangeBorderCutoffLeft} value={border_cutoff_left} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label="Right Border" onChange={handleChangeBorderCutoffRight} value={border_cutoff_right} InputProps={{ style: style }}/>
              </Box>
            </Box>
            <Box display="flex">
						  <Box>
                <TextField select label="Direction" variant="standard" onChange={handleChangeDirection} value={direction} InputProps={{ style: style }}>
                  <MenuItem value="LTR">LTR</MenuItem>
                  <MenuItem value="RTL">RTL</MenuItem>
                </TextField>
              </Box>
						  <Box>
                <TextField variant="standard" label={`Straddle Bound (${direction === 'LTR' ? 'Right' : 'Left'})`} onChange={handleChangeStraddleCutoffLower} value={straddle_cutoff_lower} InputProps={{ style: style }}/>
              </Box>
						  <Box>
                <TextField variant="standard" label={`Straddle Bound (${direction === 'RTL' ? 'Right' : 'Left'})`} onChange={handleChangeStraddleCutoffUpper} value={straddle_cutoff_upper} InputProps={{ style: style }}/>
              </Box>
            </Box>
          {/*
            <div>
              <TextField variant="standard" label="Meters / image width" onChange={handleChangeMetersPerWidth} value={meters_per_width} />
            </div>
            <div>
              <TextField variant="standard" label="Perspective Ratio" onChange={handleChangePerspectiveRatio} value={perspective_ratio} />
            </div>
            */}
            <Box display="flex">
          {/*
            <div>
              <TextField variant="standard" label="Error Margin" onChange={handleChangeErrorMargin} value={error_margin} />
            </div>
            <div>
              <TextField variant="standard" label="Maximum Aspect Ratio" onChange={handleChangeMaximumAspectRatio} value={maximum_aspect_ratio} />
            </div>
            <div>
              <TextField variant="standard" label="Raised Axle Offset (from previous tire)" onChange={handleChangeRaisedAxleOffset} value={raised_axle_offset} />
            </div>
            <div>
              <TextField variant="standard" label="Raised Axle Limit (from previous tire)" onChange={handleChangeRaisedAxleLimit} value={raised_axle_limit} />
            </div>
            */}
            </Box>
            {/*
            <div>
              <TextField variant="standard" label="Raised Axle Ratio Cutoff (ML)" onChange={handleChangeMlRaisedRatio} value={ml_raised_ratio} />
            </div>
            <div>
              <TextField variant="standard" label="Max Gap (ms)" onChange={handleChangeMaxJoinGapMs} value={max_join_gap_ms} />
            </div>
            */}
            {/*
            <div>
              <TextField variant="standard" label="Dimension Cutoff" onChange={handleChangeDimensionCutoff} value={dimension_cutoff} />
            </div>
            */}
            <Box display="flex" sx={{ml: 1}}>
              <Typography>Screen Width to Meters</Typography>
              <TextField variant="standard" label="x³" onChange={handleChangeWidthCoefficient(3)} value={width_coefficients[3]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="x²" onChange={handleChangeWidthCoefficient(2)} value={width_coefficients[2]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="x" onChange={handleChangeWidthCoefficient(1)} value={width_coefficients[1]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="C" onChange={handleChangeWidthCoefficient(0)} value={width_coefficients[0]} InputProps={{ style: style }}/>
            </Box>
            <Box display="flex" sx={{ml: 1}}>
              <Typography>Screen Height to Meters</Typography>
              <TextField variant="standard" label="x³" onChange={handleChangeHeightCoefficient(3)} value={height_coefficients[3]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="x²" onChange={handleChangeHeightCoefficient(2)} value={height_coefficients[2]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="x" onChange={handleChangeHeightCoefficient(1)} value={height_coefficients[1]} InputProps={{ style: style }}/>
              <TextField variant="standard" label="C" onChange={handleChangeHeightCoefficient(0)} value={height_coefficients[0]} InputProps={{ style: style }}/>
            </Box>
            <Box display="flex" alignItems="center">
            <TextField 
                variant="standard" 
                label="Height Ratio" 
                onChange={handleChangeHeightRatio} 
                value={height_ratio} 
                style={{ marginRight: '16px' }}
                InputProps={{ style: style }}
            />
            <Box display="flex" alignItems="center">
                <FormControlLabel 
                    control={<Switch onChange={handleToggleCaptureVideo} checked={capture_video} />} 
                    label="Capture Video" 
                />
                <FormControlLabel 
                    control={<Switch onChange={handleToggleCaptureRawVideo} checked={capture_raw_video} />} 
                    label="Capture Raw Video" 
                />
                <FormControlLabel 
                    control={<Switch onChange={handleToggleCaptureTrainingImages} checked={capture_training_images} />} 
                    label="Capture Training Images" 
                />
            </Box>
          </Box>

					</Box>
          </>
				 :
        <ProcessList siteId={siteId} onCreateFromProcess={handleCreateFromExisting} />}
      </CardContent>
			{adding && <CardActions>
        <Button sx={{ml: 1}} variant={'contained'} onClick={handleCreate}>Create</Button>
				<Button sx={{ml: 1}} variant={'contained'} onClick={() => setAdding(false) }>Cancel</Button>
			</CardActions>}
    </Card>
  );
};

export default Processes;
