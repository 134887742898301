import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetectionList from "../components/DetectionList";
import { ConnectedProductionConfigDisplay } from "../components/ProductionConfigDisplay";

import useInterval from "../hooks/useInterval";
import {
  Detection,
  EdgeProcessConfig,
  FullProcessCreate,
  ProductionDetections,
  Site,
  View
} from "../types";
import { getProductionDetectionsByLane, getSite, getViews } from "../utils/api";
import { formatDate } from "../utils";

import { PRODUCTION_VIEW_REFRESH_INTERVAL } from "../config";

import LaneSelect from "../components/LaneSelect";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  siteId: number;
  timezone: string;
  processConfigs: { [id: number]: EdgeProcessConfig };
}

interface ExpandedDetections {
  [id: number]: boolean;
}

const ProductionView: React.FC<Props> = ({ siteId, timezone, processConfigs }) => {
  const [showConfigs, setShowConfigs] = useState<boolean>(false);
  const toggleShowConfigs = () => setShowConfigs(!showConfigs);

  const [currentTime, setCurrentTime] = useState(dayjs.utc().tz(timezone));

  useInterval(() => {
    setCurrentTime(dayjs.utc().tz(timezone));
  }, 1000);

  const [lastUpdateTime, setLastUpdateTime] = useState(dayjs.utc().tz(timezone));
  const [lastHeartbeat, setLastHeartbeat] = useState<Date | undefined>(undefined);
  const [lastServerTime, setLastServerTime] = useState<Date | undefined>(undefined);
  const [expanded, setExpanded] = useState<ExpandedDetections>({} as ExpandedDetections);
  const [searchParams, setSearchParams] = useSearchParams();
  const lane_id = searchParams.get("lane_id");
  const laneId = lane_id ? +lane_id : undefined;
  const { isLoading, isError, data } = useQuery<ProductionDetections, Error>(["production", laneId], () => getProductionDetectionsByLane(laneId ?? -1), {
    enabled: !!laneId,
    onSuccess: () => {
      setLastUpdateTime(dayjs.utc().tz(timezone));
    },
  });
  const onUpdateConfig: (viewId: number, config: FullProcessCreate) => void = (viewId, config) => {};

  const [detections, setDetections] = useState<Detection[]>([] as Detection[]);
  useEffect(() => {
    if (data) {
      setDetections(data.detections);
      if (data.heartbeat_at) {
        setLastHeartbeat(data.heartbeat_at);
      }
      if (data.servertime_at) {
        setLastServerTime(data.servertime_at);
      }
    }
  }, [data]);

  const { data:views } = useQuery<View[], Error>(
    ['views', siteId, 'live'],
    () => getViews(siteId, 'live')
  );
  const { data: site } = useQuery<Site, Error>(
    ['sites', siteId],
    () => getSite(siteId),
    { enabled: siteId !== -1 }
  );
  
  const viewIds = Array.from(new Set(detections.map((d) => d.view_id))).sort();

  const queryClient = useQueryClient();
  useInterval(() => {
    if (!Object.values(expanded).some((x) => x)) {
      queryClient.invalidateQueries(["production", laneId]);
    }
  }, PRODUCTION_VIEW_REFRESH_INTERVAL);

  const onExpanded: (d: Detection, isExpanded: boolean) => void = (d, isExpanded) => {
    setExpanded({ ...expanded, [d.id]: isExpanded });
  };

  const handleChangeLaneId = (event: React.ChangeEvent<HTMLInputElement>) => setSearchParams({ lane_id: event.target.value });

  const onVerified: (d: Detection) => void = (d: Detection) => {
    setDetections(detections.map((dd) => (dd.id === d.id ? d : dd)));
  };

  let content = null;
  if (!laneId || !site) {
    content = null;
  } else if (isError) {
    content = <Typography>There has been an error loading your data.</Typography>;
  } else if (detections.length > 0) {
    let current_group: Detection[] = [];
    const best_detections: Detection[] = [];
    const hasVehicleSubclasses = site?.vehicle_subclasses?.length > 0;
    detections.filter(
      (detection) => (
        !!detection.axle_lowest_maximum && (
          detection.verified_association_type === null || (
            (detection.vehicle_class !== "Vehicle" ? detection.verified_vehicle_class !== null : true) &&
            (hasVehicleSubclasses ? detection.verified_vehicle_subclass !== null : true) &&
            (detection.verified_num_tires !== null)
          )
        )
      )
    ).forEach(
      (detection) => {
        const current_view_ids = current_group.map((d) => d.view_id);
        const current_start_time = Math.min(...current_group.map((d) => d.start_time.getTime()));
        const current_end_time = Math.max(...current_group.map((d) => d.end_time.getTime()));
        if (current_group.length === 0) {
          current_group = [detection];
        } else if (
          current_view_ids.includes(detection.view_id) ||
          current_start_time >= detection.end_time.getTime() ||
          current_end_time <= detection.start_time.getTime()
        ) {
          const best_detection = current_group.sort(
            (a, b) => a.axle_lowest_maximum! - b.axle_lowest_maximum!
          ).pop();
          if (best_detection) {
            best_detection.associated_detections = current_group;
            best_detections.push(best_detection);
          }
          current_group = [detection];
        } else {
          current_group.push(detection);
        }
      }
    );
    if (current_group.length > 0) {
      const best_detection = current_group.sort(
        (a, b) => a.axle_lowest_maximum! - b.axle_lowest_maximum!
      ).pop();
      if (best_detection) {
        best_detection.associated_detections = current_group;
        best_detections.push(best_detection);
      }
    }

    content = (
      <DetectionList
        detections={best_detections}
        enableSort={false}
        sort_column="start_time"
        sort_direction="desc"
        onVerified={onVerified}
        onExpanded={onExpanded}
        processConfigs={processConfigs}
      />
    );
  } else if (isLoading) {
    content = <CircularProgress />;
  } else {
    content = <Typography>There are no detections.</Typography>;
  }

  const HEARTBEAT_SEND_ACCEPTABLE_DELAY = 300; //seconds
  const HEARTBEAT_RECEIVE_ACCEPTABLE_DELAY = 5; //seconds

  const heartbeatSendNotDelayed =
    lastServerTime != null && currentTime != null && Math.ceil((currentTime.valueOf() - lastServerTime.valueOf()) / 1000) < HEARTBEAT_SEND_ACCEPTABLE_DELAY;

  const heartbeatReceiveNotDelayed =
    lastHeartbeat != null && lastServerTime != null && Math.ceil((lastHeartbeat.valueOf() - lastServerTime.valueOf()) / 1000) < HEARTBEAT_RECEIVE_ACCEPTABLE_DELAY;

  return (
    <Stack sx={{ width: "100%" }}>
      <Box sx={{ marginTop: "1rem" }}>
        <LaneSelect site_id={siteId} sx={{ minWidth: "10rem", mr: "0.5rem" }} value={laneId ?? ""} onChange={handleChangeLaneId} />
        <Box sx={{ marginTop: "1rem" }} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="flex-start">
            <Typography sx={{ width: "280px" }}>Time Zone:</Typography>
            <Typography>{timezone}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Typography sx={{ width: "280px" }}>User Current Time:</Typography>
            <Typography>
              <span>{currentTime.format("YYYY-MM-DD HH:mm:ss")}</span>
            </Typography>
          </Box>
          {laneId && (
            <>
              <Box display="flex" justifyContent="flex-start">
                <Typography sx={{ width: "280px" }}>Detections Refreshed:</Typography>
                <Typography>{lastUpdateTime.format("YYYY-MM-DD HH:mm:ss")}</Typography>
              </Box>
              <Box display="flex" justifyContent="flex-start">
                <Typography sx={{ width: "280px" }}>Heartbeat Received (Webapp Time):</Typography>
                {heartbeatReceiveNotDelayed && <Typography sx={{ color: "#4CAF50", fontWeight: "bold" }}>{formatDate(lastHeartbeat)}</Typography>}
                {!heartbeatReceiveNotDelayed && (
                  <>
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>{lastHeartbeat ? formatDate(lastHeartbeat) : "None"}</Typography>
                    <Typography sx={{ color: "red" }}>&nbsp;&nbsp;&nbsp;Over 5-second delay between last heartbeat sent and received!</Typography>
                  </>
                )}
              </Box>
              <Box display="flex" justifyContent="flex-start">
                <Typography sx={{ width: "280px" }}>Heartbeat Sent (GPU Time):</Typography>
                {heartbeatSendNotDelayed && <Typography sx={{ color: "#4CAF50", fontWeight: "bold" }}>{formatDate(lastServerTime, 2)}</Typography>}
                {!heartbeatSendNotDelayed && (
                  <>
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>{lastServerTime ? formatDate(lastServerTime, 2) : "None"}</Typography>
                    <Typography sx={{ color: "red" }}>&nbsp;&nbsp;&nbsp;Heartbeat has not been sent for over 5 minutes!</Typography>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
      
      <Button
        size="small"
        onClick={toggleShowConfigs}
        endIcon={showConfigs ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={{
          backgroundColor: "#f0f0f0",
          color: "black",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          width: "fit-content",
          marginTop: "1rem",
        }}
      >
        {showConfigs ? 'Hide Process Configs' : 'Show Process Configs'}
      </Button>
      <Box display="flex" gap={5} style={{ flexWrap: 'wrap' }}>
        {showConfigs && viewIds.map((viewId) => (
          <Box key={viewId} border="2px solid #bbb" padding={1} marginTop={1} textAlign="center">
            <Typography variant="h6" component="h2" fontWeight={"bold"}>
              {views?.find(view => view.id === viewId)?.name}
            </Typography>
            <ConnectedProductionConfigDisplay viewId={viewId} onUpdateConfig={onUpdateConfig} processConfigs={processConfigs} />
          </Box>
        ))}
      </Box>

      {content}
    </Stack>
  );
};

export default ProductionView;
