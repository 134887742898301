import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DetectionSummary } from '../../types';
import { isSuperuser } from '../../utils/auth';

interface Props {
  detections: DetectionSummary[];
  has_raised_axle_model?: boolean;
}

interface SummaryCount {
  [num_axles: number]: number;
}

interface Exists {
  [num_axles: number]: boolean;
}

interface CountByVehicleClass {
  [vehicle_class: string]: number;
}

interface CountByVehicleSubClass {
  [vehicle_subclass: string]: number;
}

interface CountByAxlesByVehicleClass {
  [vehicle_class: string]: SummaryCount;
}

interface CountByAxlesByVehicleSubClass {
  [vehicle_subclass: string]: SummaryCount;
}



const Summary: React.FC<Props> = ({ detections, has_raised_axle_model = false }) => {
  const raisedDetections: DetectionSummary[] = detections.filter(d => d.vehicle_class === 'Raised');
  const raisedMlDetections: DetectionSummary[] = detections.filter(d => d.vehicle_class === 'RaisedML');
  const baseDetections: DetectionSummary[] = detections
  .filter(d => !['Raised', 'RaisedML'].includes(d.vehicle_class))
  .map(d => ({
    ...d,
    vehicle_subclass: d.vehicle_subclass === null && d.vehicle_class === null ? "0" : d.vehicle_subclass,
  }));

  const axleColumns: number[] = Object.keys(
    baseDetections.filter(
      d => d.num_axles > 1 && d.num_axles <= 10
    ).reduce(
      (o: Exists, d: DetectionSummary) => ({ ...o, [d.num_axles]: true }),
      {} as Exists
    )
  ).map(
    k => +k
  ).filter(
    k => k > 1 && k <= 10
  );
  const manyAxles = Math.max(...axleColumns);

  const hasManyAxles = baseDetections.filter(d => d.num_axles > 10).length > 0;

  const vehicleClasses = Object.keys(
    baseDetections.filter(
      d => d.num_axles > 0 && d.vehicle_class
    ).reduce(
      (o, d) => ({ ...o, [d.vehicle_class]: true }),
      {}
    )
  ).sort();

  const vehicleSubClasses = Object.keys(
    baseDetections.filter(
      d => d.num_axles > 0 && d.vehicle_subclass
    ).reduce(
      (o, d) => ({ ...o, [d.vehicle_subclass as string]: true }),
      {}
    )
  ).sort();

  const singleAxlesByVehicleClass = baseDetections.filter(
    d => d.num_axles === 1 && d.vehicle_class
  ).reduce(
    (o, d) => ({ ...o, [d.vehicle_class]: (o[d.vehicle_class] ?? 0) + d.num_detections }),
    {} as CountByVehicleClass
  );

  const singleAxlesByVehicleSubClass = baseDetections.filter(
    d => d.num_axles === 1 && d.vehicle_subclass
  ).reduce(
    (o, d) => ({ ...o, [d.vehicle_subclass as string]: (o[d.vehicle_subclass as string] ?? 0) + d.num_detections }),
    {} as CountByVehicleSubClass
  );

  const manyAxlesByVehicleClass = baseDetections.filter(
    d => d.num_axles > manyAxles && d.vehicle_class
  ).reduce(
    (o, d) => ({ ...o, [d.vehicle_class]: (o[d.vehicle_class] ?? 0) + d.num_detections }),
    {} as CountByVehicleClass
  );

  const manyAxlesByVehicleSubClass = baseDetections.filter(
    d => d.num_axles > manyAxles && d.vehicle_subclass
  ).reduce(
    (o, d) => ({ ...o, [d.vehicle_subclass as string]: (o[d.vehicle_subclass as string] ?? 0) + d.num_detections }),
    {} as CountByVehicleSubClass
  );

  const vehiclesByAxleCountByVehicleClass = baseDetections.filter(
    d => d.num_axles > 1 && d.vehicle_class //&& d.num_axles <= 10
  ).reduce(
    (o, d) => ({
      ...o,
      [d.vehicle_class]: ({
        ...(o[d.vehicle_class] ?? { [d.num_axles]: 0 }),
        [d.num_axles]: (o[d.vehicle_class]?.[d.num_axles] ?? 0) + d.num_detections
      })
    }),
    {} as CountByAxlesByVehicleClass
  );

  const vehiclesByAxleCountByVehicleSubClass = baseDetections.filter(
    d => d.num_axles > 1 //&& d.vehicle_subclass //&& d.num_axles <= 10
  ).reduce(
    (o, d) => ({
      ...o,
      [d.vehicle_subclass as string]: ({
        ...(o[d.vehicle_subclass as string] ?? { [d.num_axles]: 0 }),
        [d.num_axles]: (o[d.vehicle_subclass as string]?.[d.num_axles] ?? 0) + d.num_detections
      })
    }),
    {} as CountByAxlesByVehicleSubClass
  );

  const raisedAxleVehiclesByAxleCount = raisedDetections.filter(
    d => d.num_axles > 1 //&& d.num_axles <= 10
  ).reduce(
    (o, d) => ({
      ...o,
      [d.num_axles]: (o[d.num_axles] ?? 0) + d.num_detections
    }),
    {} as SummaryCount
  );

  const raisedAxleVehiclesWithSingleAxlesCount = raisedDetections.filter(
    d => d.num_axles === 1
  ).reduce(
    (o, d) => o + d.num_detections,
    0
  );
  const raisedAxleVehiclesWithManyAxlesCount = raisedDetections.filter(
    d => d.num_axles > manyAxles
  ).reduce(
    (o, d) => o + d.num_detections,
    0
  );

  const raisedAxleVehiclesMlByAxleCount = raisedMlDetections.filter(
    d => d.num_axles //&& d.num_axles <= 10
  ).reduce(
    (o, d) => ({
      ...o,
      [d.num_axles]: (o[d.num_axles] ?? 0) + d.num_detections
    }),
    {} as SummaryCount
  );

  const raisedAxleVehiclesMlWithSingleAxlesCount = raisedMlDetections.filter(
    d => d.num_axles === 1
  ).reduce(
    (o, d) => o + d.num_detections,
    0
  );
  const raisedAxleVehiclesMlWithManyAxlesCount = raisedMlDetections.filter(
    d => d.num_axles > manyAxles
  ).reduce(
    (o, d) => o + d.num_detections,
    0
  );

  const oneAxleCounts: number = baseDetections.filter(d => d.num_axles === 1).reduce(
    (t: number, d: DetectionSummary) => t + d.num_detections,
    0
  );

  const manyAxleVehicleCount: number = baseDetections.filter(d => d.num_axles > manyAxles).reduce(
    (t: number, d: DetectionSummary) => t + d.num_detections,
    0
  );

  const manyAxleAxleCount: number = baseDetections.filter(d => d.num_axles > manyAxles).reduce(
    (t: number, d: DetectionSummary) => t + d.num_axles * d.num_detections,
    0
  );

  const countsByAxle = baseDetections.filter(d => d.num_axles > 1 && d.num_axles <= 10).reduce(
    (o: SummaryCount, d: DetectionSummary) => ({ ...o, [d.num_axles]: (o[d.num_axles] ?? 0) + d.num_detections }),
    {} as SummaryCount
  );

  return (
    <Box sx={{ flex: '1 1 0', m: '1 rem' }}>
      <Table size="small">
        <TableHead  sx={{borderBottom: 2}}>
          <TableRow>
            <TableCell>#&nbsp;Axles</TableCell>
            <TableCell title="Unassigned Single Axles">U</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>1</TableCell>
            {axleColumns.map(
              n => <TableCell key={n}>{n}</TableCell>
            )}
            {hasManyAxles && <TableCell>&gt; {manyAxles}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicleClasses.filter(vc => vc !== 'Raised' && vc !== 'RaisedML').map(
            (vc, i) => (
              <TableRow key={vc}>
                <TableCell>{vc}</TableCell>
                <TableCell>{(singleAxlesByVehicleClass[vc] ?? 0) > 0 ? singleAxlesByVehicleClass[vc] : ''}</TableCell>
                <TableCell>{Object.values(vehiclesByAxleCountByVehicleClass[vc] ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>&nbsp;</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{vehiclesByAxleCountByVehicleClass[vc]?.[n]}</TableCell>
                )}
                {hasManyAxles && <TableCell>{(manyAxlesByVehicleClass[vc] ?? 0) > 0 ? manyAxlesByVehicleClass[vc] : ''}</TableCell>}
              </TableRow>
            )
          )}
          {vehicleSubClasses.map(
            (vsc, i) => (
              <TableRow key={vsc}>
                <TableCell>{vsc}</TableCell>
                <TableCell>{(singleAxlesByVehicleSubClass[vsc] ?? 0) > 0 ? singleAxlesByVehicleSubClass[vsc] : ''}</TableCell>
                <TableCell>{Object.values(vehiclesByAxleCountByVehicleSubClass[vsc] ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>&nbsp;</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{vehiclesByAxleCountByVehicleSubClass[vsc]?.[n]}</TableCell>
                )}
                {hasManyAxles && <TableCell>{(manyAxlesByVehicleSubClass[vsc] ?? 0) > 0 ? manyAxlesByVehicleSubClass[vsc] : ''}</TableCell>}
              </TableRow>
            )
          )}
          <TableRow sx={{borderBottom: 2}}>
            <TableCell sx={{fontWeight: 'bold'}}>Total Vehicles</TableCell>
            <TableCell>{detections.filter(d => 'Raised' !== d.vehicle_class && 'RaisedML' !== d.vehicle_class && d.num_axles === 1).reduce((t, d) => t + d.num_axles * d.num_detections, 0)}</TableCell>
            <TableCell>{detections.filter(d => 'Raised' !== d.vehicle_class && 'RaisedML' !== d.vehicle_class && d.num_axles > 1).reduce((t, d) => t + d.num_detections, 0)}</TableCell>
            <TableCell>{oneAxleCounts > 0 ? oneAxleCounts : ''}</TableCell>
            {axleColumns.map(
              n => <TableCell key={n}>{countsByAxle[n]}</TableCell>
            )}
            {hasManyAxles && <TableCell>{manyAxleVehicleCount > 0 ? manyAxleVehicleCount : ''}</TableCell>}
          </TableRow>
          {(isSuperuser() || !has_raised_axle_model) && raisedDetections.length > 0 && (
            <>
              <TableRow>
                <TableCell>Total Lowered Axles</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>{baseDetections.filter(d => d.num_axles > 0).reduce((t, d) => t + d.num_axles * d.num_detections, 0) - Object.values(raisedAxleVehiclesByAxleCount ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>{oneAxleCounts - (!raisedAxleVehiclesByAxleCount? raisedAxleVehiclesByAxleCount[1] : 0)}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{countsByAxle[n] * n - (raisedAxleVehiclesByAxleCount[n] ?? 0)}</TableCell>
                )}
                {hasManyAxles && <TableCell>{manyAxleAxleCount - raisedAxleVehiclesWithManyAxlesCount}</TableCell>}
              </TableRow>
              <TableRow>
                <TableCell>Total Raised Axles</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>{Object.values(raisedAxleVehiclesByAxleCount ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>{raisedAxleVehiclesWithSingleAxlesCount > 0 ? raisedAxleVehiclesWithSingleAxlesCount : 0}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{raisedAxleVehiclesByAxleCount[n]}</TableCell>
                )}
                {hasManyAxles && <TableCell>{raisedAxleVehiclesWithManyAxlesCount > 0 ? raisedAxleVehiclesWithManyAxlesCount : ''}</TableCell>}
              </TableRow>
            </>
          )}
          {(isSuperuser() || has_raised_axle_model) && raisedMlDetections.length > 0 && (
            <>
              <TableRow>
                <TableCell>Total Lowered Axles{isSuperuser() ? ' (ML)' : ''}</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>{baseDetections.filter(d => d.num_axles > 0).reduce((t, d) => t + d.num_axles * d.num_detections, 0) - Object.values(raisedAxleVehiclesMlByAxleCount ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>{oneAxleCounts - (!raisedAxleVehiclesMlByAxleCount? raisedAxleVehiclesMlByAxleCount[1] : 0)}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{countsByAxle[n] * n - (raisedAxleVehiclesMlByAxleCount[n] ?? 0)}</TableCell>
                )}
                {hasManyAxles && <TableCell>{manyAxleAxleCount - raisedAxleVehiclesMlWithManyAxlesCount}</TableCell>}
              </TableRow>
              <TableRow>
                <TableCell>Total Raised Axles{isSuperuser() ? ' (ML)' : ''}</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>{Object.values(raisedAxleVehiclesMlByAxleCount ?? {}).reduce((t, d) => t + d, 0)}</TableCell>
                <TableCell>{raisedAxleVehiclesMlWithSingleAxlesCount > 0 ? raisedAxleVehiclesMlWithSingleAxlesCount : 0}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{raisedAxleVehiclesMlByAxleCount[n]}</TableCell>
                )}
                {hasManyAxles && <TableCell>{raisedAxleVehiclesMlWithManyAxlesCount > 0 ? raisedAxleVehiclesMlWithManyAxlesCount : ''}</TableCell>}
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Total Axles</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>{baseDetections.filter(d => d.num_axles > 0).reduce((t, d) => t + d.num_axles * d.num_detections, 0)}</TableCell>
            <TableCell>{oneAxleCounts}</TableCell>
            {axleColumns.map(
              n => <TableCell key={n}>{countsByAxle[n] * n}</TableCell>
            )}
            {hasManyAxles && <TableCell>{manyAxleAxleCount}</TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default Summary;
