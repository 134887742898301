import React from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const RaisedAxleCountSelect: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField
      select
      label={props.label || "# Raised Axles"}
      sx={{ minWidth: "10rem", mr: "0.5rem" }}
      {...props}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
              overflow: "auto",
            },
          },
        },
      }}
    >
      <MenuItem value="0">&gt;= 0</MenuItem>
      <MenuItem value="1">&gt;= 1</MenuItem>
      <MenuItem value="2">&gt;= 2</MenuItem>
      <MenuItem value="3">&gt;= 3</MenuItem>
      <MenuItem value="4">&gt;= 4</MenuItem>
      <MenuItem value="5">&gt;= 5</MenuItem>
      <MenuItem value="6">&gt;= 6</MenuItem>
      <MenuItem value="7">&gt;= 7</MenuItem>
      <MenuItem value="8">&gt;= 8</MenuItem>
      <MenuItem value="9">&gt;= 9</MenuItem>
      <MenuItem value="10">&gt;= 10</MenuItem>
    </TextField>
  );
};

export default RaisedAxleCountSelect;
