import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import { ProcessWithConfig } from '../types';
import { getProcessesWithConfigBySite, deleteProcess } from '../utils/api';
import { isSuperuser } from "../utils/auth";

interface ProcessRowProps {
  process: ProcessWithConfig;
  expanded: boolean;
  onClick: () => void;
  onDuplicate: () => void;
}

function ProcessRow({ process, expanded, onClick, onDuplicate }: ProcessRowProps) {
  const [removed, setRemoved] = useState(false);
  const handleClickRow = () => onClick();
  const handleDuplicate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDuplicate();
  };
  const removeProcess = useMutation(deleteProcess);

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeProcess.mutate(process.id)
    setRemoved(true);
  };
  if (removed) {
    return null;
  }
  return (
    <TableBody onClick={handleClickRow}>
      <TableRow>
		  	<TableCell>{process.number}: {process.name}</TableCell>
		  	<TableCell>{process.camera_name}</TableCell>
		  	<TableCell>{process.model_name}</TableCell>
		  	<TableCell>
          <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          {isSuperuser() && <Button onClick={handleDuplicate}>Duplicate</Button>}
          {isSuperuser() && <Button onClick={handleRemove}>Remove</Button>}
        </TableCell>
		  </TableRow>
      {(isSuperuser() && expanded) && (
        <TableRow>
          <TableCell>
            Direction: {process.config.direction}<br />
            Vehicle Score Cutoff: {process.config.vehicle_score_cutoff}<br />
            Axle Score Cutoff: {process.config.tire_score_cutoff}<br />
            Raised Axle Score Cutoff: {process.config.raised_axle_score_cutoff}<br />
            Raised Axle Ratio: {process.config.ml_raised_ratio}<br/>
            Out-of-Band Axle Height Limit: {process.config.raised_axle_limit}<br/>
            Vehicle Lookback Frames: {process.config.vehicle_lookback_frames}<br />
            Axle Lookback Frames: {process.config.tire_lookback_frames}<br />
          </TableCell>
          <TableCell>
            Top Border: {process.config.border_cutoff_top}<br />
            Bottom Border: {process.config.border_cutoff_bottom}<br />
            Left Border: {process.config.border_cutoff_left}<br />
            Right Border: {process.config.border_cutoff_right}<br />
            Straddle Low: {process.config.straddle_cutoff_lower}<br />
            Straddle High: {process.config.straddle_cutoff_upper}<br />
          </TableCell>
          <TableCell>
            {process.config.width_coefficients && <><span>Width Calculation:<br />{process.config.width_coefficients.reverse().map((x, i) => !x ? false : `${x} ${i > 0 ? `* x^${i}` : ''}`).filter(x => x).reverse().join(' + ')}</span><br /></>}
            {process.config.height_coefficients && <><span>Height Calculation:<br />{process.config.height_coefficients.reverse().map((x, i) => !x ? false : `${x} ${i > 0 ? `* x^${i}` : ''}`).filter(x => x).reverse().join(' + ')}</span><br /></>}
            {process.config.height_ratio_coefficients && <><span>Height Ratio Calculation:<br />{process.config.height_ratio_coefficients.reverse().map((x, i) => !x ? false : `${x} ${i > 0 ? `* x^${i}` : ''}`).filter(x => x).reverse().join(' + ')}</span><br /></>}
            {/* Height Ratio: {process.config.height_ratio} */}
          </TableCell>
          <TableCell>
            Capture Video: {process.capture_video ? "Yes" : "No"}<br/>
            Capture Raw Video: {process.capture_raw_video ? "Yes" : "No"}<br/>
            Capture Training Images: {process.capture_training_images ? "Yes" : "No"}
          </TableCell>
		    </TableRow>
      )}
    </TableBody>
  );
}

interface ProcessListProps {
	siteId: number;
  onCreateFromProcess?: (process: ProcessWithConfig) => void;
}

const ProcessList: React.FC<ProcessListProps> = ({ siteId, onCreateFromProcess }) => {
	const { isLoading, isError, data } = useQuery<ProcessWithConfig[], Error>(['processes', siteId], () => getProcessesWithConfigBySite(siteId));
  const [openProcessId, setOpenProcessId] = useState(-1);

	if (isLoading) {
		return <CircularProgress />;
	}

	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>
	}

	if (!data || data.length === 0) {
		return <Typography>There are no processes.</Typography>
	}

  const handleClickRow = (id: number) => setOpenProcessId(id === openProcessId ? -1 : id);
	return (
		<Table aria-label="Processs">
			{data.sort((a, b) => a.id - b.id).map(
        process => (
          <ProcessRow
            key={process.id}
            process={process}
            expanded={openProcessId === process.id}
            onClick={() => handleClickRow(process.id)}
            onDuplicate={() => onCreateFromProcess?.(process)}
          />
        )
      )}
		</Table>
	);
};

export default ProcessList;
